import { useCallback, useRef, useState } from 'react';

import {
  IconButton,
  Box,
  List,
  alpha,
  ListItem,
  Typography,
  ListItemText,
  Popover,
  Tooltip,
  styled,
} from '@mui/material';

import internationalization from 'src/i18n/i18n';
import { useTranslation } from 'react-i18next';

import { US, AZ, RU } from 'country-flag-icons/react/3x2';

function LanguageSwitcher() {
  const { i18n } = useTranslation();
  const { t }: { t: any } = useTranslation();
  const getLanguage = i18n.language;

  const switchLanguage = ({ lng }: { lng: any }) => {
    internationalization.changeLanguage(lng);
  };
  const ref = useRef<any>(null);
  const [isOpen, setOpen] = useState<boolean>(false);

  const handleOpen = useCallback(() => setOpen(true), []);
  const handleClose = useCallback(() => setOpen(false), []);

  return (
    <>
      <Tooltip arrow title={t('language.switcher')}>
        <IconButtonPrimary color="primary" ref={ref} onClick={handleOpen}>
          {getLanguage === 'en' && <US title="English" />}
          {getLanguage === 'az' && <AZ title="Azerbaijan" />}
          {getLanguage === 'ru' && <RU title="Русский" />}
        </IconButtonPrimary>
      </Tooltip>
      <Popover
        disableScrollLock
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Box
          sx={{
            maxWidth: 240,
          }}
        >
          <SectionHeading variant="body2" color="text.primary">
            {t('languageSwitcher')}
          </SectionHeading>
          <List
            sx={{
              p: 2,
              svg: {
                width: 26,
                mr: 1,
              },
            }}
            component="nav"
          >
            <ListItem
              className={
                getLanguage === 'en' || getLanguage === 'en-US' ? 'active' : ''
              }
              button
              onClick={() => {
                switchLanguage({ lng: 'en' });
                handleClose();
              }}
            >
              <US title="English" />
              <ListItemText
                sx={{
                  pl: 1,
                }}
                primary="English"
              />
            </ListItem>

            <ListItem
              className={getLanguage === 'AZ' ? 'active' : ''}
              button
              onClick={() => {
                switchLanguage({ lng: 'az' });
                handleClose();
              }}
            >
              <AZ title="Azerbaijan" />
              <ListItemText sx={{ pl: 1 }} primary="Azərbaycan" />
            </ListItem>

            <ListItem
              className={getLanguage === 'RU' ? 'active' : ''}
              button
              onClick={() => {
                switchLanguage({ lng: 'ru' });
                handleClose();
              }}
            >
              <RU title="Русский" />
              <ListItemText sx={{ pl: 1 }} primary="Русский" />
            </ListItem>
          </List>
        </Box>
      </Popover>
    </>
  );
}

const SectionHeading = styled(Typography)(
  ({ theme }) => `
        font-weight: ${theme.typography.fontWeightBold};
        color: ${theme.palette.secondary.main};
        display: block;
        padding: ${theme.spacing(2, 2, 0)};
`,
);

const IconButtonPrimary = styled(IconButton)(
  ({ theme }) => `
    margin-left: ${theme.spacing(1)};
    background: ${theme.colors.alpha.trueWhite[10]};
    color: ${theme.colors.alpha.trueWhite[70]};
    padding: 0;
    width: 42px;
    height: 42px;
    border-radius: 100%;
    transition: ${theme.transitions.create(['background', 'color'])};

    svg {
      width: 26px;
    }

    &.active,
    &:active,
    &:hover {
      background: ${alpha(theme.colors.alpha.trueWhite[30], 0.2)};
      color: ${theme.colors.alpha.trueWhite[100]};
    }
`,
);

export default LanguageSwitcher;
