import { Autocomplete, Grid, TextField } from '@mui/material';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { useFormik } from 'formik';
import { useStudents } from 'src/modules/students/hooks/use-students';

function FeedbackSearch() {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const { data: studentsData } = useStudents(false);

  const formik = useFormik({
    initialValues: {
      q: '',
      createdBy: '',
      student: '',
    },
    onSubmit: () => {},
  });

  let createdBy = formik.values.createdBy;
  let student = formik.values.student;
  let q = formik.values.q;

  const triggerSearch = useCallback(function (createdBy, student, q) {
    if (createdBy.trim() !== '') {
      searchParams.set('createdBy', createdBy);
    } else {
      searchParams.delete('createdBy');
    }

    if (student.trim() !== '') {
      searchParams.set('student', student);
    } else {
      searchParams.delete('student');
    }

    if (q.trim() !== '') {
      searchParams.set('q', q);
    } else {
      searchParams.delete('q');
    }

    setSearchParams(searchParams);
  }, []);

  useEffect(() => {
    const handler = setTimeout(() => {
      triggerSearch(createdBy, student, q);
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [createdBy, student, q]);

  return (
    <Grid container spacing={2} sx={{ mt: 2 }}>
      <Grid item xs={12} md={4}>
        <TextField
          label={t('inputs.labels.searchByCodeAndTopic')}
          fullWidth
          variant="outlined"
          autoComplete="off"
          {...formik.getFieldProps('q')}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <Autocomplete
          fullWidth
          options={studentsData?.usersData || []}
          onChange={(_e, newValue: any) =>
            formik.setFieldValue('student', newValue ? newValue.id : '')
          }
          value={
            studentsData?.usersData?.find((user: any) => {
              return user.id === formik.values?.student;
            }) || null
          }
          getOptionLabel={option => `${option.fullName} (${option.code})`}
          renderInput={params => (
            <TextField
              fullWidth
              {...params}
              label={t('inputs.labels.student')}
            />
          )}
        />
      </Grid>
    </Grid>
  );
}

export default FeedbackSearch;
