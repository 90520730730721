import { FC, useContext } from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { IconButton, Tooltip, Typography } from '@mui/material';

import DeleteIcon from '@mui/icons-material/Delete';

import LaunchTwoToneIcon from '@mui/icons-material/LaunchTwoTone';
// import ListAltIcon from '@mui/icons-material/ListAlt';
import { ILesson } from '../types';
import { ConfirmationModalContext } from 'src/shared/contexts/confirmation-modal';
import { useDeleteLesson } from '../hooks/use-delete-lesson';

export const LessonsTableActionButtons: FC<{ lesson: ILesson }> = ({
  lesson,
}) => {
  const { t } = useTranslation();

  const { groupId } = useParams();
  const { mutate } = useDeleteLesson();

  const { dispatch } = useContext(ConfirmationModalContext);

  return (
    <Typography noWrap>
      <Tooltip title={t('tooltip.view')} arrow>
        <IconButton
          component={RouterLink}
          to={`/dashboard/groups/${groupId}/lesson-details/${lesson.id}`}
          color="primary"
        >
          <LaunchTwoToneIcon fontSize="small" />
        </IconButton>
      </Tooltip>
      <Tooltip title={t('tooltip.delete')} arrow>
        <IconButton
          onClick={() => {
            dispatch({
              type: 'open',
              payload: {
                confirm: () => {
                  mutate({ groupId, lessonId: lesson.id });
                  dispatch({
                    type: 'close',
                  });
                },
                message: 'modal.delete',
                isOpen: true,
                buttonMessage: 'actionButtons.delete',
                color: 'error',
              },
            });
          }}
          color="error"
        >
          <DeleteIcon fontSize="small" />
        </IconButton>
      </Tooltip>
      {/* <Tooltip title={t('tooltip.list')} arrow>
        <IconButton
          component={RouterLink}
          to={`/dashboard/groups/${groupId}/results/${lesson.id}`}
          color="primary"
        >
          <ListAltIcon fontSize="small" />
        </IconButton>
      </Tooltip> */}
    </Typography>
  );
};
