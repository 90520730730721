import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { useParams, useNavigate } from 'react-router';
import { useContext, useEffect } from 'react';

import { createLessons, getGroup } from '../services';

import { queryError } from 'src/shared/utils/query-error';

import { AlertContext } from 'src/shared/contexts/Alert';
import { useRooms } from 'src/modules/lessons/hooks/use-rooms';
import useAuth from 'src/hooks/useAuth';
import { IGroup } from '../types';
import { addGroupLessonMapper } from '../mappers/add-group-lesson-mapper';
import { format } from 'date-fns';

export const useAddLesson = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { user } = useAuth();
  const { data: rooms } = useRooms(false);
  const { handleMessage } = useContext(AlertContext);

  const { groupId } = useParams();
  const isSingleSubject =
    user?.subjects?.length === 1
      ? user.subjects?.map(subject => ({
          id: subject?.id,
          name: subject?.name,
        }))[0]
      : '';

  const { data, isLoading } = useQuery(['get-group', groupId], getGroup, {
    select: ({ data }): IGroup => {
      if (data?.status === 'success') {
        return data?.data;
      }
    },
    refetchOnWindowFocus: false,
    onError: queryError,
  });

  const { mutate: createNewLesson, isLoading: isCreatingLessons } = useMutation(
    'create-lessons',
    createLessons,
    {
      onSuccess: (e: any) => {
        if (e.status === 'success') {
          handleMessage(true, t('message.successfulProcess'), 'success');
          formik.resetForm();
          navigate(-1);
        } else {
          handleMessage(true, e.message, 'error');
        }
      },
      onError: (error: any) => {
        const errorMsg =
          error?.response?.data?.message || t('message.unknownError');
        handleMessage(true, errorMsg, 'error');
      },
    },
  );

  const validationSchemaNewLessonForm = Yup.object().shape({
    topic: Yup.string().required(t('yup.inputs.topic')),
  });

  const today = format(new Date(), 'yyyy-MM-dd HH:mm');

  const formik = useFormik({
    initialValues: {
      present: data?.students || [],
      absent: data?.absent || [],
      marks: [],
      topic: '',
      isExtra: false,
      subject: isSingleSubject || '',
      room: data?.room || '',
      date: today,
    },
    onSubmit: values => {
      const addLessonBody = addGroupLessonMapper({ values });
      createNewLesson({ data: addLessonBody, groupId });
    },
    validationSchema: validationSchemaNewLessonForm,
    enableReinitialize: true,
  });

  useEffect(() => {
    if (formik.values.subject && data) {
      const students = data?.students?.filter((student: any) => {
        return student?.package?.subjects?.includes(formik.values?.subject?.id);
      });
      formik.setFieldValue('present', students);
    }
  }, [formik.values?.subject]);

  return {
    data,
    formik,
    isLoading,
    user,
    rooms,
    isCreatingLessons,
  };
};
