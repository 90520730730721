import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Column } from 'src/shared/types';
import { GroupStudentsActionButtons } from '../components/group-students-action-buttons';
import Mark from '../components/mark';

export const usePresentTable = (formik, studentsData) => {
  const { t } = useTranslation();
  const [marks, setMarks] = useState([]);

  useEffect(() => {
    const filteredMarks = marks?.filter(mark => mark.mark !== null);
    formik.setFieldValue('marks', filteredMarks);
  }, [marks]);

  const columns = useMemo(() => {
    const columns: Column[] = [
      {
        id: 'fullname',
        align: 'left',
        label: t('table.fullname'),
        extructor: item => item.fullName,
      },
      {
        id: 'marks',
        align: 'center',
        label: t('table.marks'),
        extructor: item => (
          <Mark marks={marks} setMarks={setMarks} studentId={item.id} />
        ),
      },
      {
        id: 'action',
        align: 'right',
        label: t('table.actions'),
        extructor: item => (
          <GroupStudentsActionButtons studentId={item.id} formik={formik} />
        ),
      },
    ];
    return columns;
  }, [t, studentsData, marks, setMarks]);

  return [columns];
};
