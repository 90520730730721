import PageWrapper from 'src/shared/components/page-wrapper';
import { useTranslation } from 'react-i18next';
import { useGroups } from '../hooks/use-groups';
import { useGroupsTable } from '../hooks/use-groups-table';
import { TableContext } from 'src/shared/contexts/table-context';
import { useContext } from 'react';
import GenericTableV2 from 'src/shared/components/generic-table-v2';

export function GroupsContainer() {
  const { t } = useTranslation();

  const { data } = useGroups();

  const [columns, options, routes] = useGroupsTable();
  const { page, limit, setPage, setLimit } = useContext(TableContext);

  return (
    <PageWrapper routes={routes} title={t('groups.title')}>
      <GenericTableV2
        rows={data?.data}
        columns={columns}
        options={options}
        limit={limit}
        page={page}
        rowsCount={data?.total}
        setPage={setPage}
        setLimit={setLimit}
      />
    </PageWrapper>
  );
}
