import { useTranslation } from 'react-i18next';
import { IconButton, Tooltip } from '@mui/material';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';

export const GroupAbsentActionButtons = ({ studentId, formik }) => {
  const { t } = useTranslation();

  const absentData = formik.values.absent;

  function handleDelete() {
    const updatedStudents = absentData.filter(
      (itemObj) => itemObj.id !== studentId,
    );

    const addStudent = absentData.find((itemObj) => itemObj.id === studentId);

    formik.setFieldValue('present', [...formik.values.present, addStudent]);

    formik.setFieldValue('absent', updatedStudents);
  }

  return (
    <>
      <Tooltip title={t('tooltip.addStudent')} arrow>
        <IconButton onClick={handleDelete} color="primary">
          <PersonAddAlt1Icon fontSize="small" />
        </IconButton>
      </Tooltip>
    </>
  );
};
