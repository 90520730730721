import { useTranslation } from 'react-i18next';
import { IconButton, Tooltip } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';

export const GroupStudentsActionButtons = ({ studentId, formik }) => {
  const { t } = useTranslation();

  const studentsData = formik.values.present;

  function handleDelete() {
    const updatedStudents = studentsData.filter(
      (itemObj) => itemObj.id !== studentId,
    );

    const addStudentToAbsent = studentsData.find(
      (itemObj) => itemObj.id === studentId,
    );

    formik.setFieldValue('present', updatedStudents);
    formik.setFieldValue('absent', [
      ...formik.values.absent,
      addStudentToAbsent,
    ]);
  }

  return (
    <>
      <Tooltip title={t('tooltip.delete')} arrow>
        <IconButton onClick={handleDelete} color="error">
          <PersonRemoveIcon fontSize="small" />
        </IconButton>
      </Tooltip>
    </>
  );
};
