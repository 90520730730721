import { useContext } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { AlertContext } from 'src/shared/contexts/Alert';
import { addAndUpdateFeedback } from '../services';
import { useFeedback } from './use-feedback';
import { useStudents } from 'src/modules/students/hooks/use-students';

export const useFeedbackForm = () => {
  const { t } = useTranslation();

  const { feedbackId } = useParams();

  const { data } = useFeedback();

  const { handleMessage } = useContext(AlertContext);

  const navigate = useNavigate();

  const queryClient = useQueryClient();

  const { data: studentsData } = useStudents(false);

  const validationSchema = Yup.object().shape({
    title: Yup.string().required(t('yup.inputs.title')),
    student: Yup.string().required(t('yup.inputs.student')),
  });

  const formik = useFormik({
    initialValues: {
      title: data?.title || '',
      student: data?.student || '',
      content: data?.content || '',
    },
    onSubmit: values => {
      if (values) {
        if (feedbackId) {
          mutate({ data: values, feedbackId });
        } else {
          mutate({ data: values });
        }
      }
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
  });

  const { mutate, isLoading: isSubmitting } = useMutation(
    'add-and-update-feedback',
    addAndUpdateFeedback,
    {
      onSuccess: (e: any) => {
        if (e.status === ('success' || 200)) {
          handleMessage(true, t('message.successfulProcess'), 'success');
          formik.resetForm();
          queryClient.invalidateQueries('feedbacks-get-all');
          navigate('/dashboard/feedbacks');
        } else {
          handleMessage(true, e.message, 'error');
        }
      },
      onError: (error: any) => {
        const errorMsg =
          error?.response?.data?.message || t('message.unknownError');
        handleMessage(true, errorMsg, 'error');
      },
    },
  );

  return {
    data,
    studentsData,
    formik,
    isSubmitting,
  };
};
