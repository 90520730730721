import { useQuery } from 'react-query';
import { getStudents } from '../services';
import { queryError } from 'src/shared/utils/query-error';
import { useSearchParams } from 'react-router-dom';
import { useContext } from 'react';
import { TStudensRoot } from '../types';
import { TableContext } from 'src/shared/contexts/table-context';

export const useStudents = (paginated = true) => {
  const [searchParams] = useSearchParams();
  const q = searchParams.get('q');

  const { page, limit } = useContext(TableContext);

  const { data, isLoading } = useQuery({
    queryKey: ['students-get-all', { page, limit, q, paginated }],
    queryFn: getStudents,
    select: ({ data }: { data: TStudensRoot }) => {
      if (data?.status === 'success') {
        return {
          usersData: data?.data,
          total: data?.total,
        };
      }
    },
    onError: queryError,
  });

  return { data, isLoading };
};
