import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  IGenericTableColumns,
  IOptions,
} from 'src/shared/components/generic-table/types';
import { IBreadcrumbRoutes } from 'src/shared/types';
import { ILesson } from '../types';
import { LessonsTableActionButtons } from '../components/action-buttons';
import { useParams } from 'react-router';
import { DATE_TYPES } from 'src/costants/constants';
import moment from 'moment';
import { Grid, styled } from '@mui/material';

const StyledDate = styled(Grid)`
  white-space: nowrap;
`;

export const useLessonsTable = (): [
  IGenericTableColumns,
  IOptions,
  IBreadcrumbRoutes,
] => {
  const { t } = useTranslation();

  const { groupId } = useParams();

  const options: IOptions = {
    defaultLimit: 50,
    pageLimits: [50, 100, 250, 500],
  };

  const routes = [
    {
      label: t('pages.home'),
      href: '/dashboard',
    },
    {
      label: t('groups.title'),
      href: '/dashboard/groups',
    },
    {
      label: t('lessons.title'),
      href: `/dashboard/groups/${groupId}`,
    },
    {
      label: t('addLesson.title'),
      href: '/dashboard/groups/lessons/add-lesson',
    },
  ];

  const columns = useMemo(() => {
    const columns: IGenericTableColumns = [
      {
        id: 'topic',
        align: 'left',
        label: t('table.topic'),
        extructor: (item: ILesson) => item.topic,
      },
      {
        id: 'subject',
        align: 'left',
        label: t('table.subject'),
        extructor: (item: ILesson) => item?.subject?.name,
      },
      {
        id: 'date',
        align: 'center',
        label: t('table.date'),
        extructor: (item: ILesson) => (
          <StyledDate>
            {item?.date
              ? moment(item?.date).format(DATE_TYPES.mdy)
              : t('table.noDate')}
          </StyledDate>
        ),
      },

      {
        id: 'actions',
        align: 'right',
        label: t('table.actions'),
        extructor: (item: ILesson) => {
          return <LessonsTableActionButtons lesson={item} />;
        },
      },
    ];
    return columns;
  }, [t]);

  return [columns, options, routes];
};
