import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  IGenericTableColumns,
  IOptions,
} from 'src/shared/components/generic-table/types';
import { IBreadcrumbRoutes } from 'src/shared/types';
import { IFeedback } from '../types';
import FeedbackTableActionButtons from '../components/action-buttons';

export const useFeedbackTable = (
  type?: string,
): [IGenericTableColumns, IOptions, IBreadcrumbRoutes] => {
  const { t } = useTranslation();

  const options: IOptions = {
    defaultLimit: 50,
    pageLimits: [50, 100, 250, 500],
  };

  const routes = [
    {
      label: t('pages.home'),
      href: '/dashboard',
    },
    {
      label: t('feedbacks.title'),
      href: '/dashboard/feedbacks',
    },
  ];

  const columns = useMemo(() => {
    const columns: IGenericTableColumns = [
      {
        id: 'name',
        align: 'left',
        label: t('table.title'),
        extructor: (item: IFeedback) => item?.title,
      },
      {
        id: 'student',
        align: 'center',
        label: t('table.student'),
        extructor: (item: IFeedback) => item?.student?.fullName,
      },
      {
        id: 'actions',
        align: 'right',
        label: t('table.actions'),
        extructor: (item: IFeedback) => {
          return <FeedbackTableActionButtons feedback={item} />;
        },
      },
    ];

    return columns;
  }, [t]);

  return [columns, options, routes];
};
