import { AxiosResponse } from 'axios';

import getAuthToken from 'src/utils/getAuthToken';
import axios from 'src/utils/axios';
import queryStringMaker from 'src/shared/utils/queryStringMaker';

export const getFeedbacks = async (query: any): Promise<AxiosResponse> => {
  const [, queryObj] = query.queryKey;

  try {
    return await axios.get(`/api/v1/feedbacks${queryStringMaker(queryObj)}`, {
      headers: {
        Authorization: 'Bearer ' + getAuthToken(),
      },
    });
  } catch (e) {
    return e;
  }
};

export const getFeedback = async (
  feedbackId: string,
): Promise<AxiosResponse> => {
  try {
    return await axios.get(`/api/v1/feedbacks/${feedbackId}`, {
      headers: {
        Authorization: 'Bearer ' + getAuthToken(),
      },
    });
  } catch (e) {
    return e;
  }
};

export const addAndUpdateFeedback = async ({
  data,
  feedbackId = null,
}: {
  data: any;
  feedbackId?: string;
}): Promise<AxiosResponse> => {
  const url = feedbackId
    ? `/api/v1/feedbacks/${feedbackId}`
    : '/api/v1/feedbacks';
  try {
    const { data: newData } = await axios({
      url,
      method: feedbackId ? 'patch' : 'post',
      data,
      headers: {
        Authorization: 'Bearer ' + getAuthToken(),
      },
    });
    return newData;
  } catch (e) {
    return e;
  }
};

export const makeDeleteFeedback = async (feedbackId: string) => {
  try {
    return await axios.delete(`/api/v1/feedbacks/${feedbackId}`, {
      headers: {
        Authorization: 'Bearer ' + getAuthToken(),
      },
    });
  } catch (e) {
    return e;
  }
};
