import { FeedbackDetails } from '../pages/feedback-details';
import { FeedbackFormPage } from '../pages/feedback-from-page';
import { Feedbacks } from '../pages/feedbacks';

const feedbacksRouter = {
  path: 'feedbacks',
  children: [
    {
      path: '',
      element: <Feedbacks />,
    },
    {
      path: 'add-feedback',
      element: <FeedbackFormPage />,
    },
    {
      path: ':feedbackId',
      element: <FeedbackDetails />,
    },
    {
      path: 'edit/:feedbackId',
      element: <FeedbackFormPage />,
    },
  ],
};

export default feedbacksRouter;
