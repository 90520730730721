import { AxiosResponse } from 'axios';

import getAuthToken from 'src/utils/getAuthToken';
import axios from 'src/utils/axios';

export const getLessons = async (groupId: string): Promise<AxiosResponse> => {
  try {
    return await axios.get(
      `/api/v2/teacher-api/groups/${groupId}?hasLessons=true`,
      {
        headers: {
          Authorization: 'Bearer ' + getAuthToken(),
        },
      },
    );
  } catch (e) {
    return e;
  }
};

export const makeDeleteLesson = async ({
  lessonId,
  groupId,
}: {
  lessonId: string;
  groupId: string;
}) => {
  try {
    return await axios.delete(
      `/api/v1/teacher/groups/${groupId}/lessons/${lessonId}`,
      {
        headers: {
          Authorization: 'Bearer ' + getAuthToken(),
        },
      },
    );
  } catch (e) {
    return e;
  }
};
