import Collapse from '@mui/material/Collapse';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import { useNavigate, useParams } from 'react-router';

import {
  Box,
  ListItemText,
  Divider,
  ListItem,
  IconButton,
  styled,
  Badge,
  alpha,
  css,
  Typography,
  Tooltip,
  List,
} from '@mui/material';

import Scrollbar from 'src/components/Scrollbar';
import Text from 'src/components/Text';
import { useLessonDetails } from '../hooks/use-lesson-details';
import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';

const ListWrapper = styled(List)(
  () => `
    .MuiDivider-root:first-of-type {
        display: none;
    }
  `,
);

function Row({ row, feedbacks, t, mark, isPresent }) {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const { groupId } = useParams();
  const { lessonId } = useParams();

  const NotificationsBadge = styled(Badge)(
    ({ theme, badgeContent }) => css`
      .MuiBadge-badge {
        background-color: ${badgeContent !== '0'
          ? alpha(theme.palette.success.main, 0.9)
          : 'red'};
        color: ${theme.palette.success.contrastText};
        margin: 3px;
        min-width: 17px;
        height: 17px;
        padding: 0;

        &::after {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          border-radius: 50%;
          box-shadow: 0 0 0 1px ${alpha(theme.palette.success.main, 0.3)};
          content: '';
        }
      }
    `,
  );
  const StyledTypography = styled(Typography)`
    height: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
  `;

  const handleClick = function (item) {
    navigate(
      `/dashboard/groups/${groupId}/lesson-details/${lessonId}/add-feedback-to-lesson/${item}`,
    );
  };

  return (
    <>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell component="th" scope="row">
          {row.name}
        </TableCell>
        {isPresent && (
          <TableCell component="th" scope="row" align="center">
            {mark}
          </TableCell>
        )}
        <TableCell align="right">
          <Tooltip title={t('tooltip.show')}>
            <NotificationsBadge
              badgeContent={feedbacks?.length || '0'}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => setOpen(!open)}
              >
                {open ? (
                  <KeyboardArrowUpIcon color="primary" />
                ) : (
                  <KeyboardArrowDownIcon color="primary" />
                )}
              </IconButton>
            </NotificationsBadge>
          </Tooltip>

          <IconButton
            aria-label="expand row"
            onClick={() => handleClick(row?.id)}
          >
            <ChatBubbleOutlineOutlinedIcon fontSize="small" color="primary" />
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box
              sx={{
                height: 120,
              }}
            >
              <Scrollbar>
                <ListWrapper disablePadding>
                  {feedbacks?.length ? (
                    feedbacks?.map(feedback => (
                      <Fragment key={feedback?.id}>
                        <Divider />
                        <ListItem
                          sx={{
                            py: 1.7,
                            px: 2.5,
                          }}
                        >
                          <ListItemText
                            primary={
                              <Text color="black">{feedback?.title}</Text>
                            }
                            primaryTypographyProps={{
                              variant: 'h4',
                              noWrap: true,
                            }}
                            secondary={feedback?.content}
                            secondaryTypographyProps={{
                              variant: 'subtitle2',
                              noWrap: true,
                            }}
                          />

                          {/* <Button
                                variant="text"
                                color="secondary"
                                sx={{
                                  backgroundColor: `${theme.colors.secondary.lighter}`,
                                  '&:hover': {
                                    backgroundColor: `${theme.colors.secondary.main}`,
                                    color: `${theme.palette.getContrastText(
                                      theme.colors.secondary.main,
                                    )}`,
                                  },
                                }}
                              >
                                {t('View')}
                              </Button> */}
                        </ListItem>
                      </Fragment>
                    ))
                  ) : (
                    <StyledTypography variant="h4">
                      {t('typography.notFeedback')}
                    </StyledTypography>
                  )}
                </ListWrapper>
              </Scrollbar>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

export default function CollapsibleTable({ data, title }) {
  const { t } = useTranslation();
  const { data: lessonData } = useLessonDetails();
  const isPresent = title === t('present');
  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell>{title} </TableCell>
            {isPresent && (
              <TableCell align="center">{t('table.marks')}</TableCell>
            )}
            <TableCell align="right">{t('table.feedback')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.map((student: any, i) => {
            const feedbacks = lessonData?.feedbacks?.filter(
              (feedback: any) => feedback.student === student.id,
            );
            const marks = lessonData?.marks;
            return (
              <Row
                key={student.id}
                row={student}
                feedbacks={feedbacks}
                t={t}
                mark={marks?.[i]?.mark ?? '-'}
                isPresent={isPresent}
              />
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
