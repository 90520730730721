import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  IGenericTableColumns,
  IOptions,
} from 'src/shared/components/generic-table/types';
import { IBreadcrumbRoutes } from 'src/shared/types';
import StudentsTableActionButtons from '../components/action-buttons';
import { IStudent } from '../types';

export const useStudentsTable = (
  type?: string,
): [IGenericTableColumns, IOptions, IBreadcrumbRoutes] => {
  const { t } = useTranslation();

  const options: IOptions = {
    defaultLimit: 50,
    pageLimits: [50, 100, 250, 500]
  };

  const routes = [
    {
      label: t('pages.home'),
      href: '/dashboard',
    },
    {
      label: t('students.title'),
      href: '/dashboard/students',
    },
  ];

  const columns = useMemo(() => {
    const columns: IGenericTableColumns = [
      {
        id: 'code',
        align: 'left',
        label: t('table.code'),
        extructor: (item: IStudent) => item.code,
      },
      {
        id: 'fullname',
        align: 'center',
        label: t('table.fullname'),
        extructor: (item: IStudent) => item.fullName,
      },
      {
        id: 'actions',
        align: 'right',
        label: t('table.actions'),
        extructor: (item: IStudent) => {
          return <StudentsTableActionButtons student={item} />;
        },
      },
    ];

    return columns;
  }, [t]);

  return [columns, options, routes];
};
