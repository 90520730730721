import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';

import SuspenseLoader from 'src/components/SuspenseLoader';
import groupsRouter from 'src/modules/groups/router';
import studentsRouter from 'src/modules/students/router';
// import Learning from 'src/content/dashboards/Learning';
import feedbacksRouter from 'src/modules/feedbacks/router';
import maintenanceRouter from 'src/modules/maintenance/routes';

const Loader = Component => props =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Dashboards

const Analytics = Loader(
  lazy(() => import('src/content/dashboards/Analytics')),
);

const Students = Loader(lazy(() => import('src/content/dashboards/Students')));
const ProfilePage = Loader(
  lazy(() => import('src/modules/profile/pages/profile')),
);

const dashboardsRoutes = [
  {
    path: '',
    element: <Navigate to="/dashboard/groups" />,
  },
  {
    path: '',
    element: <Analytics />,
  },
  groupsRouter,
  studentsRouter,
  feedbacksRouter,
  maintenanceRouter,
  {
    path: 'profile',
    element: <ProfilePage />,
  },
  // {
  //   path: '/dashboard/learning',
  //   element: <Learning />
  // },
];

export default dashboardsRoutes;
