import * as Yup from 'yup';
import { Formik } from 'formik';
import { FC, useContext, useEffect, useRef, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Visibility, VisibilityOff } from '@mui/icons-material';

import {
  Box,
  Button,
  FormHelperText,
  TextField,
  Link,
  CircularProgress,
  InputAdornment,
  IconButton,
  FormControl,
  InputLabel,
  OutlinedInput,
} from '@mui/material';
import useAuth from 'src/hooks/useAuth';
import { useTranslation } from 'react-i18next';
import { AlertContext } from 'src/shared/contexts/Alert';

const LoginForm: FC = () => {
  const { login } = useAuth() as any;
  const { t }: { t: any } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);
  const { handleMessage } = useContext(AlertContext);

  useEffect(() => {
    return () => {
      // Cleanup function to prevent state update on unmounted component
      isMounted.current = false;
    };
  }, []);

  const isMounted = useRef(true);

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
      <Formik
        initialValues={{
          email:
            process.env.REACT_APP_ENV === 'development'
              ? 'teacher3@aspirans.az'
              : '',
          password: process.env.REACT_APP_ENV === 'development' ? '123456' : '',
          terms: true,
          submit: null,
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .email(t('yup.email.validation'))
            //
            .max(255)
            .required(t('yup.email.required')),
          password: Yup.string()
            .min(3, t('yup.password.validation'))
            .required(t('yup.password.required')),
          terms: Yup.boolean().oneOf([true], t('yup.required.terms')),
        })}
        onSubmit={async (
          values,

          { setStatus, setSubmitting },
        ): Promise<void> => {
          try {
            const response = await login(values.email, values.password);
            if (isMounted.current) {
              setStatus({ success: true });
              setSubmitting(false);
            }
          } catch (err) {
            if (isMounted.current) {
              setStatus({ success: false });
              handleMessage(true, t('message.invalidCredentials'), 'error');
              setSubmitting(false);
            }
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }): JSX.Element => {
          return (
            <Box component="form" onSubmit={handleSubmit}>
              <TextField
                error={Boolean(touched.email && errors.email)}
                fullWidth
                margin="normal"
                autoFocus
                label={t('inputs.labels.email')}
                name="email"
                onBlur={handleBlur}
                onChange={handleChange}
                type="email"
                value={values.email}
                variant="outlined"
              />
              {Boolean(touched.email && errors.email) && (
                <FormHelperText error>{errors.email}</FormHelperText>
              )}
              <FormControl
                sx={{ width: '100%' }}
                variant="outlined"
                margin="normal"
                error={Boolean(touched.password && errors.password)}
              >
                <InputLabel htmlFor="outlined-adornment-password">
                  {t('inputs.labels.password')}
                </InputLabel>
                <OutlinedInput
                  name="password"
                  id="outlined-adornment-password"
                  type={showPassword ? 'text' : 'password'}
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton edge="end" onClick={handleShowPassword}>
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Password"
                />
              </FormControl>
              {Boolean(touched.password && errors.password) && (
                <FormHelperText error>{errors.password}</FormHelperText>
              )}
              <Box
                alignItems="center"
                display="flex"
                justifyContent="space-between"
              >
                {/* <FormControlLabel
              control={
                <Checkbox
                  checked={values.terms}
                  name="terms"
                  color="primary"
                  onChange={handleChange}
                />
              }
              label={
                <>
                  <Typography variant="body2">
                    {t('I accept the')}{' '}
                    <Link component="a" href="#">
                      {t('terms and conditions')}
                    </Link>
                    .
                  </Typography>
                </>
              }
            /> */}
                <Link
                  component={RouterLink}
                  to="/recover-password"
                  sx={{ textAlign: 'right', width: '100%' }}
                >
                  <b>{t('login.lostPassword')}</b>
                </Link>
              </Box>

              {Boolean(touched.terms && errors.terms) && (
                <FormHelperText error>{errors.terms}</FormHelperText>
              )}

              <Button
                sx={{
                  mt: 3,
                }}
                color="primary"
                startIcon={
                  isSubmitting ? <CircularProgress size="1rem" /> : null
                }
                disabled={isSubmitting}
                type="submit"
                fullWidth
                size="large"
                variant="contained"
              >
                {t('login.signIn')}
              </Button>
            </Box>
          );
        }}
      </Formik>
    </>
  );
};

export default LoginForm;
