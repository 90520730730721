import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import PageWrapper from 'src/shared/components/page-wrapper';
import { Box, Button, Card, Typography } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useFeedbacks } from '../hooks/use-feedbacks';
import { useFeedbackTable } from '../hooks/use-feedback-table';
import FeedbackSearch from '../components/feedback-search';
import { TableContext } from 'src/shared/contexts/table-context';
import GenericTableV2 from 'src/shared/components/generic-table-v2';

export function FeedbacksContainer() {
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState<boolean>(true);

  const { page, limit, setPage, setLimit } = useContext(TableContext);

  const { data } = useFeedbacks();

  const [columns, options, routes] = useFeedbackTable();

  const handleFilter = () => {
    setIsOpen(!isOpen);
  };

  return (
    <PageWrapper
      isPaper={false}
      routes={routes}
      title={t('feedbacks.title')}
      action={
        <Link
          to="/dashboard/feedbacks/add-feedback"
          style={{ textDecoration: 'none' }}
        >
          <Button variant="contained">{t('buttons.addFeedback')}</Button>
        </Link>
      }
    >
      <Card
        sx={{
          p: 2,
          mb: 3,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          borderRadius: '4px',
        }}
      >
        <Box
          onClick={handleFilter}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          sx={{ width: '100%', cursor: 'pointer' }}
        >
          <Typography>Filter</Typography>
          {!isOpen && <KeyboardArrowDownIcon sx={{ cursor: 'pointer' }} />}
          {isOpen && <KeyboardArrowUpIcon sx={{ cursor: 'pointer' }} />}
        </Box>
        {isOpen && <FeedbackSearch />}
      </Card>
      <Card>
        <GenericTableV2
          rows={data?.feedbacksData}
          columns={columns}
          options={options}
          limit={limit}
          page={page}
          rowsCount={data?.total}
          setPage={setPage}
          setLimit={setLimit}
        />
        
      </Card>
    </PageWrapper>
  );
}
