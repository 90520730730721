import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useContext } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { AlertContext } from 'src/shared/contexts/Alert';
import { useStudents } from 'src/modules/students/hooks/use-students';
import { useFeedback } from 'src/modules/feedbacks/hooks/use-feedback';
import { addAndUpdateFeedback } from 'src/modules/feedbacks/services';
import { feedbackMapper } from '../mappers/add-group-lesson-mapper';

export const useLessonFeedbackForm = () => {
  const { handleMessage } = useContext(AlertContext);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { lessonId } = useParams();
  const { studentId } = useParams();

  const { data, isLoading: isFeedbackSubmitting } = useFeedback();

  const { data: studentsData, isLoading: isStudentsSubmitting } =
    useStudents(false);

  const validationSchema = Yup.object().shape({
    title: Yup.string().required(t('yup.inputs.title')),
    student: Yup.object().required(t('yup.inputs.student')).nullable(),
  });

  // get current student by id
  const currentStudent = studentsData?.usersData.find(
    (student: any) => student?.id === studentId,
  );

  const formik = useFormik({
    initialValues: {
      title: '',
      student: currentStudent || null,
      content: '',
    },
    onSubmit: values => {
      let mappedValues;
      if (studentId) {
        mappedValues = feedbackMapper(values, lessonId);
      } else {
        mappedValues = feedbackMapper(values);
      }

      mutate({ data: mappedValues });
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
  });

  const { mutate, isLoading: isSubmitting } = useMutation(
    ['add-and-update-feedback', 'groups-get-all'],
    addAndUpdateFeedback,
    {
      onSuccess: (e: any) => {
        if (e.status === ('success' || 200)) {
          handleMessage(true, t('message.successfulProcess'), 'success');
          formik.resetForm();
          queryClient.invalidateQueries('get-lesson');
          if (Boolean(studentId)) navigate(-1);
        } else {
          handleMessage(true, e.message, 'error');
        }
      },
      onError: (error: any) => {
        const errorMsg =
          error?.response?.data?.message || t('message.unknownError');
        handleMessage(true, errorMsg, 'error');
      },
    },
  );

  return {
    data,
    studentsData,
    formik,
    isSubmitting,
    isFeedbackSubmitting,
    isStudentsSubmitting,
    studentId,
  };
};
