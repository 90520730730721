import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Column } from 'src/shared/types';
import { GroupAbsentActionButtons } from '../components/group-absent-action-buttons';

export const useAbsentTable = (formik, absentData) => {
  const { t } = useTranslation();

  const columns = useMemo(() => {
    const columns: Column[] = [
      {
        id: 'fullname',
        align: 'left',
        label: t('table.fullname'),
        extructor: (item) => item.fullName,
      },
      {
        id: 'action',
        align: 'right',
        label: t('table.actions'),
        extructor: (item) => (
          <GroupAbsentActionButtons studentId={item.id} formik={formik} />
        ),
      },
    ];
    return columns;
  }, [t, absentData]);

  return [columns];
};
