import { Outlet } from 'react-router';
import { LessonDetailsContainer } from '../containers/lesson-details-container';

export const LessonDetails = () => {
  return (
    <>
      <LessonDetailsContainer />
      <Outlet />
    </>
  );
};
