import { AxiosResponse } from 'axios';

import getAuthToken from 'src/utils/getAuthToken';
import axios from 'src/utils/axios';
import queryStringMaker from '../../../shared/utils/queryStringMaker';

export const getGroups = async (query: any): Promise<AxiosResponse> => {
  const [, queryKeys] = query.queryKey;

  try {
    return await axios.get(
      `/api/v1/teacher/groups${queryStringMaker(queryKeys)}`,
      {
        headers: {
          Authorization: 'Bearer ' + getAuthToken(),
        },
      },
    );
  } catch (e) {
    return e;
  }
};

export const getRooms = async (query: any): Promise<AxiosResponse> => {
  const [, queryKeys] = query.queryKey;

  try {
    return await axios.get(`/api/v1/rooms${queryStringMaker(queryKeys)}`, {
      headers: {
        Authorization: 'Bearer ' + getAuthToken(),
      },
    });
  } catch (e) {
    return e;
  }
};

export const getGroup = async (reactQueryParams): Promise<AxiosResponse> => {
  const [, groupId] = reactQueryParams.queryKey;

  try {
    return await axios.get(`/api/v1/teacher/groups/${groupId}`, {
      headers: {
        Authorization: 'Bearer ' + getAuthToken(),
      },
    });
  } catch (e) {
    return e;
  }
};

export const getGroupLessons = async (
  reactQueryParams,
): Promise<AxiosResponse> => {
  const [, groupId, queryKeys] = reactQueryParams.queryKey;

  try {
    return await axios.get(
      `/api/v1/teacher/groups/${groupId}/lessons${queryStringMaker(queryKeys)}`,
      {
        headers: {
          Authorization: 'Bearer ' + getAuthToken(),
        },
      },
    );
  } catch (e) {
    return e;
  }
};

export const getLesson = async (reactQueryParams): Promise<AxiosResponse> => {
  const [, groupId, lessonId] = reactQueryParams.queryKey;

  try {
    return await axios.get(
      `api/v1/teacher/groups/${groupId}/lessons/${lessonId}`,
      {
        headers: {
          Authorization: 'Bearer ' + getAuthToken(),
        },
      },
    );
  } catch (e) {
    return e;
  }
};

export const createLessons = async ({
  data,
  groupId,
}): Promise<AxiosResponse> => {
  try {
    const { data: newData } = await axios.post(
      `/api/v1/teacher/groups/${groupId}/lessons`,
      data,
      {
        headers: {
          Authorization: 'Bearer ' + getAuthToken(),
        },
      },
    );
    return newData;
  } catch (e) {
    return e;
  }
};
