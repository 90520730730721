import { useQuery } from 'react-query';
import { queryError } from 'src/shared/utils/query-error';
import { useSearchParams } from 'react-router-dom';
import { useContext } from 'react';
import { getFeedbacks } from '../services';
import { TFeedbacksRoot } from '../types';
import { TableContext } from 'src/shared/contexts/table-context';

export const useFeedbacks = () => {
  const [searchParams] = useSearchParams();
  const q = searchParams.get('q');
  const student = searchParams.get('student');

  const { page, limit } = useContext(TableContext);

  const { data } = useQuery({
    queryKey: ['feedbacks-get-all', { page, limit, q, student }],
    queryFn: getFeedbacks,
    select: ({ data }: { data: TFeedbacksRoot }) => {
      if (data?.status === 'success') {
        return {
          feedbacksData: data?.data,
          total: data?.total,
        };
      }
    },
    onError: queryError,
  });

  return { data };
};
