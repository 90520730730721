import { useContext } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { AlertContext } from 'src/shared/contexts/Alert';
import { makeDeleteFeedback } from '../services';
import { useTranslation } from 'react-i18next';

export const useDeleteFeedback = () => {
  const { handleMessage } = useContext(AlertContext);
  const { t } = useTranslation();

  const queryClient = useQueryClient();

  const { mutate } = useMutation('delete-feedback', makeDeleteFeedback, {
    onSuccess: (e: any) => {
      if (e.status === 204) {
        handleMessage(true, t('message.successfulProcess'), 'success');
        queryClient.invalidateQueries('feedbacks-get-all');
      } else {
        handleMessage(true, e.message, 'error');
      }
    },
    onError: (error: any) => {
      const errorMsg =
        error?.response?.data?.message || t('message.unknownError');
      handleMessage(true, errorMsg, 'error');
    },
  });

  return { mutate };
};
