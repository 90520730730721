import { FC, useContext } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { IconButton, Tooltip, Typography } from '@mui/material';

import LaunchTwoToneIcon from '@mui/icons-material/LaunchTwoTone';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import { IFeedback } from '../types';
import { ConfirmationModalContext } from 'src/shared/contexts/confirmation-modal';
import { useDeleteFeedback } from '../hooks/use-delete-feedback';

const FeedbackTableActionButtons: FC<{ feedback: IFeedback }> = ({
  feedback,
}) => {
  const { t } = useTranslation();

  const { dispatch } = useContext(ConfirmationModalContext);

  const { mutate } = useDeleteFeedback();

  return (
    <Typography noWrap>
      <Tooltip title={t('tooltip.view')} arrow>
        <IconButton
          component={RouterLink}
          to={`/dashboard/feedbacks/${feedback.id}`}
          color="primary"
        >
          <LaunchTwoToneIcon fontSize="small" />
        </IconButton>
      </Tooltip>
      <Tooltip title={t('tooltip.edit')} arrow>
        <IconButton
          component={RouterLink}
          to={`/dashboard/feedbacks/edit/${feedback.id}`}
          color="primary"
        >
          <EditIcon fontSize="small" />
        </IconButton>
      </Tooltip>
      <Tooltip title={t('tooltip.delete')} arrow>
        <IconButton
          onClick={() => {
            dispatch({
              type: 'open',
              payload: {
                confirm: () => {
                  mutate(feedback.id);
                  dispatch({
                    type: 'close',
                  });
                },
                message: 'modal.delete',
                isOpen: true,
                buttonMessage: 'actionButtons.delete',
                color: 'error',
              },
            });
          }}
          color="error"
        >
          <DeleteIcon fontSize="small" />
        </IconButton>
      </Tooltip>
    </Typography>
  );
};

export default FeedbackTableActionButtons;
