const enJSON = {
  students: {
    title: 'Students',
  },
  homework: {
    title: 'Homework',
    homeworkForm: 'Homework Form',
  },
  groups: {
    title: 'Groups',
    groupDetails: {
      title: 'Group Details',
      addLesson: 'Add Lesson',
    },
  },
  transactions: {
    title: 'Transactions',
  },
  internalTransactions: {
    title: 'Internal Transactions',
  },
  internalTransactionDetails: {
    title: 'Internal Transaction Details',
  },
  completion: {
    name: 'Completion',
  },
  stats: {
    title: 'Stats',
    internalBalance: 'Internal Balance',
  },
  popover: {
    title: 'Profile',
    signOut: 'Sign Out',
    lessonMode: 'Lesson Mode',
  },
  lessons: {
    title: 'Lessons',
  },
  feedbacks: {
    title: 'Feedbacks',
    addFeedback: 'Add Feedback',
    feedback: 'Feedback',
  },
  addLesson: {
    title: 'Add Lesson',
  },
  pages: {
    home: 'Home',
    studentDetails: 'Student Details',
    lessons: 'Lessons',
    addLesson: {
      title: 'Add Lesson',
      lessonDetails: {
        title: 'Lesson Details',
        homeworkWillBeAddedLater: 'Homework will be added later',
        createHomework: 'Create Homework',
      },
    },
  },
  modal: {
    pleaseCheckYourFileAgain:
      'Please check your homework again, percentage sum should be equal to 100.',
    notFoundData: 'Not Found Data',
    theDataInTheSublistIsLeftBlank: 'The data in the sublist is left blank',
    delete: 'Are you sure you want to delete?',
  },
  message: {
    studentNotFound: 'Student not found',
    homeworkNotFound: 'Homework not found',
    homeworkNotAdded: 'Homework not added',
    successfulProcess: 'Process was successful',
    successfulImageProcess: 'Image successfully updated',
    unknownError: 'Unknown error',
    dataNotFound: 'Data not found!',
    noHomeworkAddedDoYouWantToAdd: 'No homework added. Do you want to add?',
    invalidCredentials: 'Invalid credentials, please log in again',
  },
  heading: {
    general: 'General',
    management: 'Management',
    ourTeam: 'Our Team',
  },
  accounting: {
    title: 'Accounting',
    invoiceDetails: {
      title: 'Invoice',
      issuedOn: 'Issued On',
      issuedBy: 'Issued By',
      teacherBalanceBeforePayment: 'Balance Before Payment',
      teacherBalanceAfterPayment: 'Balance After Payment',
    },
  },
  profile: {
    title: 'Profile',
    changeCover: 'Change Cover',
    tabs: {
      myAccount: 'My Account',
      aboutMe: 'About Me',
    },
  },
  language: {
    switcher: 'Languange Switcher',
  },
  table: {
    name: 'Name',
    title: 'Title',
    marks: 'Marks',
    code: 'Code',
    link: 'Link',
    percentage: 'Percentage',
    fullname: 'Full Name',
    actions: 'Actions',
    status: 'Status',
    email: 'Email',
    room: 'Room',
    students: 'Students',
    subject: 'Subject',
    category: 'Category',
    amount: 'Amount',
    date: 'Date',
    type: 'Type',
    fatherName: 'Father Name',
    topic: 'Topic',
    student: 'Student',
    feedback: 'Feedback',
    weekdays: 'Weekdays',
    noDate: 'No date chosen',
  },
  tabs: {
    lessons: 'Lessons',
    students: 'Students',
    resources: 'Resources',
    statistics: 'Statistics',
  },
  tooltip: {
    view: 'View',
    lockedAllHomeworkForStudent: 'Locked All Homework For Student',
    lockedHomeworkForStudent: 'Locked Homework For Student',
    unlockHomeworkForStudent: 'Unlock Homework For Student',
    finishEditing: 'Finish Editing',
    startEditing: 'Start Editing',
    list: 'List',
    delete: 'Delete',
    edit: 'Edit',
    addStudent: 'Add Student',
    show: 'Click here to show all feddbacks',
  },
  inputs: {
    labels: {
      changePassword: 'Change Password',
      addFeedback: 'Add feedback',
      changeEmail: 'Change Email',
      currentPassword: 'Current Password',
      password: 'Password',
      passwordConfirm: 'Password Confirm',
      email: 'Email',
      marks: 'Marks',
      mark: 'Mark',
      name: 'Name',
      content: 'Content',
      title: 'Title',
      surname: 'Surname',
      subject: 'Subject',
      gender: 'Gender',
      university: 'University',
      interests: 'Interests',
      dateOfBirth: 'Birth Of Date',
      number: 'Number',
      topic: 'Topic',
      biography: 'Biography',
      searchByCodeAndTopic: 'Search By Code And Topic',
      code: 'Code',
      search: 'Search',
      student: 'Student',
      fatherName: 'Father Name',
      fullName: 'Full Name',
      query: 'Query',
      note: 'Note',
      tags: 'Tags',
      description: 'Description',
      rowsPerPage: 'Rows Per Page',
      isExtra: 'Extra Lesson',
      notExtra: 'Not Extra Lesson',
      group: 'Group',
      teacher: 'Teacher',
      extra: 'Extra Lesson',
      room: 'Room',
      date: 'Date',
    },
    placeholders: {},
  },
  buttons: {
    confirm: 'Confirm',
    importExcelFile: 'Import Excel File',
    add: 'Add',
    edit: 'Edit',
    seeMore: 'See More',
    close: 'Close',
    addHomework: 'Add Homework',
    confirmWithoutAdd: 'Confirm Without Add',
    addFeedback: 'Add Feedback',
    cancel: 'Cancel',
    update: 'Update',
    yes: 'Yes',
    no: 'No',
  },
  actionButtons: {
    delete: 'Delete',
  },
  comments: {
    name: 'Comments',
  },
  yup: {
    currentPassword: 'Current password is requierd',
    password: {
      required: 'Password is requierd',
      validation: 'Password should contain minimum 3 characters',
    },
    passwordConfirm: {
      required: 'Confirm password is required.',
      correctly: 'Does not match the repeat password',
    },
    email: {
      required: 'Email is required',
      validation: 'Please enter a valid email',
    },
    inputs: {
      duration: {
        required: 'Duration is required',
        max: 'Max 200 min.',
      },
      percentage: {
        required: 'Percentage is required',
        total: 'Total should be 100%',
      },
      name: 'Name is required',
      surname: 'Surname is required',
      gender: 'Gender is required',
      university: 'University is required',
      topic: 'Topic is required',
      room: 'Room is required',
      lessonStarting: 'Lesson Starting is required',
      lessonEnding: 'Lesson Ending is required',
      title: 'Title is required',
      link: 'Link is required',
      homework: 'Homework is required',
      biography: 'Biography is required',
      number: 'Number is required',
      student: 'Student is required',
    },
  },
  subject: 'Subject',
  room: 'Room',
  lessonStarting: 'Lesson Starting',
  lessonEnding: 'Lesson Ending',
  duration: 'Duration',
  description: 'Description',
  title: 'Title',
  percentage: 'Percentage',
  link: 'Link',
  present: 'Present',
  absent: 'Absent',
  topic: 'Topic',
  login: {
    signIn: 'Sign In',
    info: 'Fill in the fields below to sign into your account.',
    lostPassword: 'Lost Password ?',
    signUp: 'Sign Up Here',
  },
  recoverPassword: {
    title: 'Recover Password',
    info: 'Enter the email used for registration to reset your password',
    sendPassword: 'Send Me a New Password',
    signInAgain: 'Want to try to sign in again?',
    clickHere: 'Click Here',
  },
  typography: {
    present: 'Present',
    absent: 'Absent',
    currentMaintenance: 'The site is currently down for maintenance',
    appologizeMaintenance: 'We apologize for any inconveniences caused',
    notFeedback: 'Not feedback found!',
  },
  languageSwitcher: 'Language',
  amount: 'Amount',
  selectInput: {
    female: 'Female',
    male: 'Male',
    unknown: 'Unknown',
  },
};
export default enJSON;
