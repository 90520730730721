import { useParams } from 'react-router';
import { useQuery } from 'react-query';
import { queryError } from 'src/shared/utils/query-error';
import { getLesson } from '../services';
import { ILesson } from 'src/modules/lessons/types';

export const useLessonDetails = () => {
  const { groupId } = useParams();
  const { lessonId } = useParams();

  const { data, isLoading } = useQuery(
    ['get-lesson', groupId, lessonId],
    getLesson,
    {
      select: ({ data }): ILesson => {
        if (data?.status === 'success') {
          return data?.data;
        }
      },
      onError: queryError,
    },
  );
  return {
    data,
    isLoading,
  };
};
