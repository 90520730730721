import { useTranslation } from 'react-i18next';
import { Autocomplete, Box, Grid, Typography, TextField } from '@mui/material';
import { OmInput } from 'src/shared/components/form-components/om-input';
import { IStudent } from 'src/modules/students/types';
import { LoadingButton } from '@mui/lab';

export const FeedbackForm = ({ data, studentsData, formik, isSubmitting }) => {
  const { t } = useTranslation();

  return (
    <>
      <Box component="form" onSubmit={formik.handleSubmit}>
        <Grid container item xs={12} md={6} spacing={2}>
          <Grid item xs={12}>
            <OmInput
              formik={formik}
              label={t('inputs.labels.title')}
              name="title"
            />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              fullWidth={true}
              options={studentsData?.usersData || []}
              disablePortal
              value={
                studentsData && formik.values.student
                  ? studentsData?.usersData.find(
                      (user: IStudent) => user.id === formik.values.student,
                    )
                  : null
              }
              onChange={(_e, newValue: IStudent) => {
                formik.setFieldValue('student', newValue ? newValue.id : '');
              }}
              getOptionLabel={(option: IStudent) => {
                return `${option.fullName} (${option.code})`;
              }}
              renderInput={params => (
                <TextField
                  fullWidth
                  {...params}
                  label={t('inputs.labels.student')}
                />
              )}
            />
            {!!formik.errors.student && formik.touched.student && (
              <Typography
                sx={{
                  fontSize: '13px',
                  fontWeight: 'bold',
                  color: '#FF1943',
                }}
              >
                {formik.errors.student}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12}>
            <OmInput
              multiline={true}
              rows={3}
              formik={formik}
              label={t('inputs.labels.content')}
              name="content"
            />
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{ mt: 2, display: 'flex', justifyContent: 'flex-start' }}
        >
          <LoadingButton
            type="submit"
            loading={isSubmitting}
            variant="contained"
            fullWidth
            color="primary"
            sx={{ width: { xs: '100%', md: 200 } }}
          >
            {data ? t('buttons.update') : t('buttons.addFeedback')}
          </LoadingButton>
        </Grid>
      </Box>
    </>
  );
};
