import { Groups } from '../pages/groups';
import GroupDetails from '../pages/group-details';
import AddLesson from '../pages/add-lesson';
import { LessonDetails } from '../pages/lesson-details';
import { LessonFeedback } from '../pages/lesson-feedback';

const groupsRouter = {
  path: 'groups',
  children: [
    {
      path: '',
      element: <Groups />,
    },
    {
      path: ':groupId',
      element: <GroupDetails />,
    },
    {
      path: ':groupId/add-lesson',
      element: <AddLesson />,
    },
    {
      path: ':groupId/lesson-details/:lessonId',
      element: <LessonDetails />,
      children: [
        {
          path: 'add-feedback-to-lesson/:studentId',
          element: <LessonFeedback />,
        },
        {
          path: 'add-feedback-to-student',
          element: <LessonFeedback />,
        },
      ],
    },
  ],
};

export default groupsRouter;
