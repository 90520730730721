import { Grid, Box, Card } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { IFeedback } from '../types';

export const FeedbackDetailsForm = ({ data }: { data: IFeedback }) => {
  const { t } = useTranslation();

  return (
    <Grid item xs={12} sx={{ mb: 5 }}>
      <Card sx={{ p: 3 }}>
        <Box component="form">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4}>
              <Box
                sx={{
                  border: '1px solid #c4c4c4',
                  padding: '12px 10px',
                  borderRadius: '10px',
                }}
              >
                {t('inputs.labels.title')}: {data?.title}
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Box
                sx={{
                  border: '1px solid #c4c4c4',
                  padding: '12px 10px',
                  borderRadius: '10px',
                }}
              >
                {t('inputs.labels.content')}: {data?.content}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Card>
    </Grid>
  );
};
