import { useQuery } from 'react-query';
import { useParams } from 'react-router';
import { getGroupLessons } from '../services';
import { queryError } from 'src/shared/utils/query-error';
import { TLessonRoot } from 'src/modules/lessons/types';
import { useContext } from 'react';
import { TableContext } from 'src/shared/contexts/table-context';

export const useGroupLessons = () => {
  const { groupId } = useParams();
  const { page, limit } = useContext(TableContext);

  const { data } = useQuery(
    ['get-group-lessons', groupId, { page, limit }],
    getGroupLessons,
    {
      select: ({ data }: { data: TLessonRoot }) => {
        if (data?.status === 'success') {
          return { data: data?.data, total: data?.total };
        }
      },
      onError: queryError,
    },
  );

  return {
    data,
  };
};
