import { Grid, Box, Card, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CollapsibleTable from './details-table';
import moment from 'moment';
import { DATE_TYPES } from 'src/costants/constants';
import { weekdaysMaker } from 'src/shared/utils/weekdays-format';

export const LessonDetailsForm = ({ data }: { data: any }) => {
  const { t } = useTranslation();
  return (
    <Grid item xs={12} sx={{ mb: 5 }}>
      <Card sx={{ p: 3 }}>
        <Box component="form">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={3}>
              <Box
                sx={{
                  border: '1px solid #c4c4c4',
                  padding: '12px 10px',
                  borderRadius: '10px',
                }}
              >
                {t('inputs.labels.topic')}: {data?.topic}
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Box
                sx={{
                  border: '1px solid #c4c4c4',
                  padding: '12px 10px',
                  borderRadius: '10px',
                }}
              >
                {t('inputs.labels.group')}: {data?.group?.name}
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Box
                sx={{
                  border: '1px solid #c4c4c4',
                  padding: '12px 10px',
                  borderRadius: '10px',
                }}
              >
                {t('inputs.labels.teacher')}: {data?.teacher?.fullName}
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Box
                sx={{
                  border: '1px solid #c4c4c4',
                  padding: '12px 10px',
                  borderRadius: '10px',
                }}
              >
                {t('inputs.labels.subject')}: {data?.subject?.name}
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Box
                sx={{
                  border: '1px solid #c4c4c4',
                  padding: '12px 10px',
                  borderRadius: '10px',
                }}
              >
                {t('inputs.labels.room')}: {data?.room?.number} (
                {data?.room?.branch.name})
              </Box>
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <Box
                sx={{
                  border: '1px solid #c4c4c4',
                  padding: '12px 10px',
                  borderRadius: '10px',
                }}
              >
                {t('inputs.labels.isExtra')}:{' '}
                {data?.isExtra
                  ? t('inputs.labels.extra')
                  : t('inputs.labels.notExtra')}
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Box
                sx={{
                  border: '1px solid #c4c4c4',
                  padding: '12px 10px',
                  borderRadius: '10px',
                }}
              >
                {t('inputs.labels.date')}:{' '}
                {moment(data?.date).format(DATE_TYPES.mdy)}
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Box
                sx={{
                  border: '1px solid #c4c4c4',
                  padding: '12px 10px',
                  borderRadius: '10px',
                }}
              >
                {t('inputs.labels.weekdays')}:{' '}
                {weekdaysMaker(data?.group?.weekdays)}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Card>
      <Grid container justifyContent="space-between">
        <Grid item xs={12} md={5.7} py={6}>
          <Grid sx={{ py: 1 }}>
            <CollapsibleTable
              data={data?.present}
              title={t('typography.present')}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} md={5.7} py={6}>
          <Grid sx={{ py: 1 }}>
            <CollapsibleTable
              data={data?.absent}
              title={t('typography.absent')}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
