import { useNavigate, useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Card,
  CircularProgress,
  Grid,
  Typography,
} from '@mui/material';
import PageWrapper from 'src/shared/components/page-wrapper';
import { useLessonDetails } from '../hooks/use-lesson-details';
import { LessonDetailsForm } from '../components/lesson-details-form';
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';

export const LessonDetailsContainer = () => {
  const { t } = useTranslation();

  const { groupId } = useParams();
  const { lessonId } = useParams();
  const navigate = useNavigate();

  const { data, isLoading } = useLessonDetails();

  const routes = [
    {
      label: t('pages.home'),
      href: '/dashboard',
    },
    {
      label: t('groups.title'),
      href: '/dashboard/groups',
    },
    {
      label: t('lessons.title'),
      href: `/dashboard/groups/${groupId}`,
    },
    {
      label: data?.topic ? data?.topic : '',
      href: `/dashboard/lessons/${data?.id}`,
    },
  ];

  return (
    <PageWrapper
      isPaper={false}
      routes={routes}
      title={data?.topic ? data?.topic : ''}
      action={
        <Button
          variant="contained"
          sx={{ display: 'flex', gap: '5px' }}
          onClick={() => {
            navigate(
              `/dashboard/groups/${groupId}/lesson-details/${lessonId}/add-feedback-to-student`,
            );
          }}
        >
          <ChatBubbleOutlineOutlinedIcon
            fontSize="medium"
            sx={{ color: 'white' }}
          />
          <Typography variant="h4">{t('buttons.addFeedback')}</Typography>
        </Button>
      }
    >
      {isLoading ? (
        <Card
          sx={{
            height: '500px',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CircularProgress size={40} />
        </Card>
      ) : (
        <>
          <Grid container>
            <LessonDetailsForm data={data} />
          </Grid>
        </>
      )}
    </PageWrapper>
  );
};
