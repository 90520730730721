import { useState } from 'react';
import cuid from 'cuid';
import type { FC, ChangeEvent } from 'react';

import {
  Box,
  Checkbox,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableContainer,
  TableRow,
  Typography,
  Card,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import LoadingCircle from '../Loading-circle';

import TableHeader from './components/table-header';
import { IGenericTableColumns, IOptions } from './types';

interface GenericTableProps {
  data: any[];
  columns: IGenericTableColumns;
  select?: boolean;
  loading?: boolean;
  search?: 'only-search' | 'with-filter' | null;
  searchWith?: string | null;
  searchFilters?: string[];
  filterTitle?: string;
  options?: IOptions;
}
const applyFilters = (
  data: any[],
  query: string,
  selectedFilter: string,
  searchWith,
  filterTitle,
) => {
  if (selectedFilter === 'all') {
    if (query && searchWith) {
      return data.filter(datas =>
        datas[searchWith].toLowerCase().includes(query.toLowerCase()),
      );
    }

    return data;
  } else {
    const newData = data.filter(
      datas => datas[filterTitle.toLowerCase()] === selectedFilter,
    );
    if (query && searchWith) {
      return newData.filter(datas =>
        datas[searchWith].toLowerCase().includes(query.toLowerCase()),
      );
    }

    return newData;
  }
};

const applyPagination = (
  filteredData: any[],
  page: number,
  limit: number,
): any[] => {
  return filteredData?.slice(page * limit, page * limit + limit);
};

const GenericTable: FC<GenericTableProps> = ({
  data,
  columns,
  select = false,
  search = null,
  searchWith = '',
  filterTitle = '',
  options = {
    defaultLimit: 10,
  },
}) => {
  const [selectedItems, setSelectedInvoices] = useState<string[]>([]);
  const { t }: { t: any } = useTranslation();

  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(options.defaultLimit);
  const [query, setQuery] = useState<string>('');
  const [selectedFilter, setSelectedFilter] = useState<string>('all');

  const handleSelectAllRow = (event: ChangeEvent<HTMLInputElement>): void => {
    setSelectedInvoices(
      event.target.checked ? data?.map(invoice => invoice.id) : [],
    );
  };

  const handleSelectOneInvoice = (
    event: ChangeEvent<HTMLInputElement>,
    invoiceId: string,
  ): void => {
    if (!selectedItems.includes(invoiceId)) {
      setSelectedInvoices(prevSelected => [...prevSelected, invoiceId]);
    } else {
      setSelectedInvoices(prevSelected =>
        prevSelected.filter(id => id !== invoiceId),
      );
    }
  };

  const handlePageChange = (event: any, newPage: number): void => {
    setPage(newPage);
  };

  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setLimit(parseInt(event.target.value));
  };

  const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
    setQuery(event.currentTarget.value);
  };

  const filteredData: any = applyFilters(
    data,
    query,
    selectedFilter,
    searchWith,
    filterTitle,
  );
  const paginatedData = applyPagination(filteredData, page, limit);
  const selectedBulkActions = selectedItems.length > 0;
  const selectedSomeRow =
    selectedItems.length > 0 && selectedItems.length < data?.length;
  const selectedAllRow = selectedItems.length === data?.length;

  if (!data) {
    return (
      <Box
        sx={{
          minHeight: '600px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <LoadingCircle />
      </Box>
    );
  }

  return (
    <>
      <TableHeader
        selectedAllRow={selectedAllRow}
        selectedSomeRow={selectedSomeRow}
        handleSelectAllRow={handleSelectAllRow}
        selectedBulkActions={selectedBulkActions}
        pagelength={data.length}
        selectedItems={selectedItems.length}
        select={select}
        search={search}
        handleSearch={handleSearch}
        searchWith={searchWith}
      />
      <Divider />
      {paginatedData.length !== 0 ? (
        <>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  {columns.map(column => {
                    return (
                      <TableCell
                        key={cuid()}
                        align={column.align}
                        padding="normal"
                        sx={{ textTransform: 'none' }}
                      >
                        {column.label}
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {paginatedData.map(row => {
                  const isInvoiceSelected = selectedItems.includes(row.id);
                  return (
                    <TableRow hover key={cuid()} selected={isInvoiceSelected}>
                      {columns.map(
                        (
                          { id, align, render, extructor = item => item },
                          index,
                        ) => {
                          if (index === 0 && select) {
                            return (
                              <TableCell key={cuid()}>
                                <Box display="flex" alignItems="center">
                                  <Checkbox
                                    checked={isInvoiceSelected}
                                    onChange={event =>
                                      handleSelectOneInvoice(event, row.id)
                                    }
                                    value={isInvoiceSelected}
                                  />
                                  <Box pl={1}>
                                    <Typography noWrap variant="subtitle2">
                                      {render
                                        ? render(extructor(row))
                                        : extructor(row)}
                                    </Typography>
                                  </Box>
                                </Box>
                              </TableCell>
                            );
                          }
                          return (
                            <TableCell align={align} key={id}>
                              {render ? render(extructor(row)) : extructor(row)}
                            </TableCell>
                          );
                        },
                      )}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <Box p={2}>
            <TablePagination
              labelRowsPerPage={t('inputs.labels.rowsPerPage')}
              component="div"
              count={filteredData.length}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleLimitChange}
              page={page}
              rowsPerPage={limit}
              rowsPerPageOptions={[10, 25, 50]}
            />
          </Box>
        </>
      ) : (
        <Typography
          sx={{
            py: 10,
          }}
          variant="h3"
          fontWeight="normal"
          color="text.secondary"
          align="center"
        >
          {t('message.dataNotFound')}
        </Typography>
      )}
    </>
  );
};

export default GenericTable;
