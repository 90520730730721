export default function queryStringMaker(queryObj) {
  let cleanQueryObj: any = {};

  for (const [key, value] of Object.entries(queryObj)) {
    if (value === null || key === 'paginated') {
    } else {
      if (key === 'page') {
        cleanQueryObj[key] = Number(value) + 1;
      } else {
        cleanQueryObj[key] = value;
      }
    }
  }

  if (queryObj['paginated'] === false) {
    delete cleanQueryObj.page;
    delete cleanQueryObj.limit;
  }

  const queryString = new URLSearchParams(cleanQueryObj);
  return queryString ? `?${queryString}` : '';
}
