import { useTranslation } from 'react-i18next';
import GroupStudent from 'src/modules/groups/components/group-student';
import { LessonForm } from './lesson-form';
import { Grid, Box, Card, Typography, CircularProgress } from '@mui/material';
import { useAddLesson } from '../hooks/use-add-lesson';
import SubmitButton from 'src/shared/components/Submit-button';
import { AbsentTable } from './absent-table';

const AddLessonForm = () => {
  const { t } = useTranslation();

  const { formik, isCreatingLessons, user, rooms, isLoading } = useAddLesson();

  return (
    <Box component="form" onSubmit={formik.handleSubmit} sx={{ mb: 2 }}>
      {isLoading ? (
        <Card
          sx={{
            height: '500px',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CircularProgress size={40} />
        </Card>
      ) : (
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Card sx={{ p: 2 }}>
              <LessonForm formik={formik} user={user} rooms={rooms} />
            </Card>
          </Grid>
          <Grid item xs={12} sx={{ py: 2 }}>
            <Typography variant="h3" sx={{ mb: 1 }}>
              {t('typography.present')}
            </Typography>
            <GroupStudent formik={formik} />
          </Grid>

          <Grid item xs={12} sx={{ py: 2 }}>
            <Typography variant="h3" sx={{ mb: 1 }}>
              {t('typography.absent')}
            </Typography>
            <AbsentTable formik={formik} />
          </Grid>

          <Grid item xs={12} container justifyContent="end">
            <SubmitButton
              load={isCreatingLessons}
              sx={{ width: { xs: '100%', md: 220 } }}
            >
              {t('buttons.confirm')}
            </SubmitButton>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default AddLessonForm;
