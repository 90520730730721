import TableContextProvider from 'src/shared/provider/table-context-provider';
import { StudentsContainer } from '../containers/students';

export function Students() {
  return (
    <TableContextProvider>
      <StudentsContainer />
    </TableContextProvider>
  );
}
