import { useTranslation } from 'react-i18next';
import PageWrapper from 'src/shared/components/page-wrapper';
import { Card, CircularProgress, Grid } from '@mui/material';
import { useFeedback } from '../hooks/use-feedback';
import { FeedbackDetailsForm } from '../components/feedback-details-form';

export const FeedbackDetailsContainer = () => {
  const { t } = useTranslation();

  const { data, isLoading } = useFeedback();

  const routes = [
    {
      label: t('pages.home'),
      href: '/dashboard',
    },
    {
      label: t('feedbacks.title'),
      href: '/dashboard/feedbacks',
    },
    {
      label: `${data ? data?.title : ''}`,
      href: `/dashboard/feedbacks/${data?.id}`,
    },
  ];

  return (
    <PageWrapper
      isPaper={false}
      routes={routes}
      title={`${t('feedbacks.feedback')}: ${data ? data?.title : ''}`}
    >
      {isLoading ? (
        <Card
          sx={{
            height: '500px',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CircularProgress size={40} />
        </Card>
      ) : (
        <Grid container>
          <FeedbackDetailsForm data={data} />
        </Grid>
      )}
    </PageWrapper>
  );
};
