import TableContextProvider from 'src/shared/provider/table-context-provider';
import { FeedbacksContainer } from '../containers/feedbacks-container';

export function Feedbacks() {
  return (
    <TableContextProvider>
      <FeedbacksContainer />
    </TableContextProvider>
  );
}
