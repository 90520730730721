import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { queryError } from 'src/shared/utils/query-error';
import { getFeedback } from '../services';
import { IFeedback } from '../types';

export const useFeedback = () => {
  const { feedbackId } = useParams();

  const { data, isLoading } = useQuery(
    'get-feedback',
    () => {
      if (feedbackId) {
        return getFeedback(feedbackId);
      }
    },
    {
      select: ({ data }): IFeedback => {
        if (data?.status === 'success') {
          return data?.data;
        }
      },
      onError: queryError,
    },
  );

  return {
    data,
    isLoading,
  };
};
