import { useTranslation } from 'react-i18next';
import { Card, Grid } from '@mui/material';
import PageWrapper from 'src/shared/components/page-wrapper';
import { useFeedbackForm } from '../hooks/use-feedback-form';
import { FeedbackForm } from '../components/feedback-form';

export const FeedbackFormContainer = () => {
  const { t } = useTranslation();

  const { data, studentsData, formik, isSubmitting } = useFeedbackForm();

  const routes = [
    {
      label: t('pages.home'),
      href: '/dashboard',
    },
    {
      label: t('feedbacks.title'),
      href: '/dashboard/feedbacks',
    },
    {
      label: data ? data?.title : t('feedbacks.addFeedback'),
      href: data
        ? `/dashboard/addFeedbacks/${data?.id}`
        : '/dashboard/addFeedbacks/add-feedback',
    },
  ];

  return (
    <PageWrapper
      isPaper={false}
      routes={routes}
      title={data ? data?.title : t('feedbacks.addFeedback')}
    >
      <Card sx={{ p: 2 }}>
        <Grid item xs={12}>
          <FeedbackForm
            data={data}
            studentsData={studentsData}
            formik={formik}
            isSubmitting={isSubmitting}
          />
        </Grid>
      </Card>
    </PageWrapper>
  );
};
