import { Card, Grid, Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import LoadingCircle from 'src/components/Loading-circle';
import PageWrapper from 'src/shared/components/page-wrapper';
import { useStudentEditForm } from '../hooks/use-student-edit-form';
import { StudentDetailsForm } from '../components/student-details-form';

const StudentDetailsContainer = () => {
  const { t } = useTranslation();

  const { studentId } = useParams();

  const { formik, routes, data, isLoading, onKeyDown } =
    useStudentEditForm(studentId);

  return (
    <PageWrapper routes={routes} title={t('students.title')}>
      <Card variant="outlined">
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h3" sx={{ px: 3, pt: 2 }}>
              {data?.fullName}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Box>
              {isLoading ? (
                <LoadingCircle />
              ) : (
                <StudentDetailsForm formik={formik} onKeyDown={onKeyDown} />
              )}
            </Box>
          </Grid>
        </Grid>
      </Card>
    </PageWrapper>
  );
};

export default StudentDetailsContainer;
