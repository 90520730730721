import { useQuery } from 'react-query';
import { queryError } from 'src/shared/utils/query-error';
import { TGroupsRoot } from '../types';
import { useContext } from 'react';
import { TableContext } from 'src/shared/contexts/table-context';
import { getGroups } from '../services';

export const useGroups = () => {
  const { page, limit } = useContext(TableContext);

  const { data } = useQuery({
    queryKey: ['groups-get-all', { page, limit }],
    queryFn: getGroups,
    select: ({ data }: { data: TGroupsRoot }) => {
      if (data?.status === 'success') {
        return data;
      }
    },
    onError: queryError,
  });

  return {
    data,
  };
};
