import { IDateTypes, IRegularExpressions } from '../types';

export const DATE_TYPES: IDateTypes = {
  mdy: 'DD-MM-YYYY',
  ll: 'll',
  mdyt: 'YYYY-MM-DD HH:mm',
  hms: 'HH:mm:ss',
  hm: 'HH:mm',
};

export const REGULAR_EXPRESSIONS: IRegularExpressions = {
  phone: /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g,
  nameAndEmail:
    /^[AaBbCcÇçDdEeƏəFfGgĞğHhXxIıİiJjKkQqLlMmNnOoÖöPpRrSsŞşTtUuÜüVvYyZz]+$/,
  decimalNumber: /^\d+\.?\d*$/,
  integer: /^\d+$/,
};
