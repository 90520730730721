import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';

import SuspenseLoader from 'src/components/SuspenseLoader';
import Guest from 'src/components/Guest';

const Loader = Component => props =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

const LoginCover = Loader(
  lazy(() => import('src/content/pages/Auth/container')),
);
const RegisterCover = Loader(
  lazy(() => import('src/content/pages/Auth/Register/Cover')),
);
const RegisterBasic = Loader(
  lazy(() => import('src/content/pages/Auth/Register/Basic')),
);

const RecoverPassword = Loader(
  lazy(() => import('src/content/pages/Auth/RecoverPassword')),
);

const accountRoutes = [
  {
    path: '',
    element: <Navigate replace to="login" />,
  },
  {
    path: 'login',
    element: (
      <Guest>
        <LoginCover />
      </Guest>
    ),
  },
  {
    path: 'register',
    element: (
      <Guest>
        <RegisterCover />
      </Guest>
    ),
  },
  {
    path: 'recover-password',
    element: <RecoverPassword />,
  },
  {
    path: 'register',
    element: (
      <Guest>
        <RegisterCover />
      </Guest>
    ),
  },
  {
    path: 'register-basic',
    element: <RegisterBasic />,
  },
  {
    path: 'register-cover',
    element: <RegisterCover />,
  },
];

export default accountRoutes;
