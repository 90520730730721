import { Box, Card, Tooltip, Typography, styled } from '@mui/material';
import Slider from 'react-slick';

import { useTranslation } from 'react-i18next';
import Scrollbar from 'src/components/Scrollbar';

export default function SidebarHeader() {
  const { t } = useTranslation();

  const SidebarWrapper = styled(Box)(
    ({ theme }) => `
          position: fixed;
          left: 0;
          top: 0;
          height: 100%;
          background: ${theme.colors.alpha.white[100]};
          width: 440px;
      `,
  );

  const SidebarContent = styled(Box)(
    ({ theme }) => `
    display: flex;
    flex-direction: column;
    padding: ${theme.spacing(6)};
  `,
  );

  const TypographyH1 = styled(Typography)(
    ({ theme }) => `
      font-size: ${theme.typography.pxToRem(33)};
  `,
  );

  const CardImg = styled(Card)(
    ({ theme }) => `
      border-radius: 100%;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      position: relative;
      border: 1px solid ${theme.colors.alpha.black[10]};
      transition: ${theme.transitions.create(['border'])};
      position: absolute;
  
      &:hover {
        border-color: ${theme.colors.primary.main};
      }
  `,
  );

  const graduatesData = [
    {
      id: 1,
      fullname: 'Милана  Намазова',
      image:
        'https://res.cloudinary.com/ddiwagbct/image/upload/v1706771551/team-photos/lj3gkyto9dhoy333ioid.webp',
      link: 'https://aspirans.az/#teachers',
      styles: {
        width: 80,
        height: 80,
        left: 0,
        top: 40,
      },
    },
    {
      id: 2,
      fullname: 'Камилла  Новрузова',
      image:
        'https://res.cloudinary.com/ddiwagbct/image/upload/v1706772428/team-photos/a97iw56rtjosjzqp1gwf.webp',
      link: 'https://aspirans.az/#teachers',
      styles: {
        width: 90,
        height: 90,
        left: 80,
        top: 0,
      },
    },
    {
      id: 3,
      fullname: 'Анар Ахмедов',
      image:
        'https://res.cloudinary.com/ddiwagbct/image/upload/v1706771551/team-photos/b3cyavx1scjisvz4cuaz.webp',
      link: 'https://aspirans.az/#teachers',
      styles: {
        width: 110,
        height: 110,
        top: -162,
        left: 170,
      },
    },
    {
      id: 4,
      fullname: 'Айхан Гардашханов',
      image:
        'https://res.cloudinary.com/ddiwagbct/image/upload/v1706771551/team-photos/rp2cut6i1y483zydlzcx.webp',
      link: 'https://aspirans.az/#teachers',
      styles: {
        width: 70,
        height: 70,
        top: -162,
        left: 259,
      },
    },
    {
      id: 5,
      fullname: 'Александра Шляхтина',
      image:
        'https://res.cloudinary.com/ddiwagbct/image/upload/v1706771551/team-photos/zoj3si139c1h4oaclo43.webp',
      link: 'https://aspirans.az/#teachers',
      styles: {
        width: 80,
        height: 80,
        left: 0,
        top: 40,
      },
    },
    {
      id: 6,
      fullname: 'Ибрагим  Оруджбейли',
      image:
        'https://res.cloudinary.com/ddiwagbct/image/upload/v1706771551/team-photos/owizi1bc707o2tbp2oj7.webp',
      link: 'https://aspirans.az/#teachers',
      styles: {
        width: 90,
        height: 90,
        left: 80,
        top: 0,
      },
    },
    {
      id: 7,
      fullname: 'Диана Клименко',
      image:
        'https://res.cloudinary.com/ddiwagbct/image/upload/v1706771551/team-photos/dtemmf9zawfm2i7bwztq.webp',
      link: 'https://aspirans.az/#teachers',
      styles: {
        width: 110,
        height: 110,
        top: -162,
        left: 170,
      },
    },
    {
      id: 8,
      fullname: 'Дашдамир Рагимов',
      image:
        'https://res.cloudinary.com/ddiwagbct/image/upload/v1706771551/team-photos/fdetwbem8x0ipg0ps77f.webp',
      link: 'https://aspirans.az/#teachers',
      styles: {
        width: 70,
        height: 70,
        top: -162,
        left: 259,
      },
    },
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  return (
    <SidebarWrapper
      sx={{
        display: { xs: 'none', md: 'flex' },
      }}
    >
      <Scrollbar>
        <SidebarContent>
          <Box mt={6}>
            <TypographyH1
              variant="h1"
              sx={{
                mb: 7,
              }}
            >
              {t('heading.ourTeam')}
            </TypographyH1>
            <Slider {...settings}>
              <Box
                sx={{
                  position: 'relative',
                  width: 300,
                  height: 200,
                }}
              >
                {graduatesData
                  .slice(0, 4)
                  .map(({ id, fullname, image, link, styles }) => (
                    <Tooltip key={id} arrow placement="top" title={fullname}>
                      <Box
                        sx={{
                          ...styles,
                          position: 'relative',
                        }}
                      >
                        <CardImg
                          sx={{
                            width: '100%',
                            height: '100%',
                          }}
                        >
                          <a href={link} target="_blank" rel="noreferrer">
                            <img
                              style={{
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover',
                              }}
                              alt={fullname}
                              src={image}
                            />
                          </a>
                        </CardImg>
                      </Box>
                    </Tooltip>
                  ))}
              </Box>
              <Box
                sx={{
                  position: 'relative',
                  width: 300,
                  height: 200,
                }}
              >
                {graduatesData
                  .slice(4, 8)
                  .map(({ id, fullname, image, link, styles }) => (
                    <Tooltip key={id} arrow placement="top" title={fullname}>
                      <Box
                        sx={{
                          ...styles,
                          position: 'relative',
                        }}
                      >
                        <CardImg
                          sx={{
                            width: '100%',
                            height: '100%',
                          }}
                        >
                          <a href={link} target="_blank" rel="noreferrer">
                            <img
                              style={{
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover',
                              }}
                              alt="Şəbnəm Süleymanlı"
                              src={image}
                            />
                          </a>
                        </CardImg>
                      </Box>
                    </Tooltip>
                  ))}
              </Box>
            </Slider>
          </Box>
        </SidebarContent>
      </Scrollbar>
    </SidebarWrapper>
  );
}
