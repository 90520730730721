import { AxiosResponse } from 'axios';

import getAuthToken from 'src/utils/getAuthToken';
import axios from 'src/utils/axios';
import queryStringMaker from 'src/shared/utils/queryStringMaker';

export const getStudents = async (query): Promise<AxiosResponse> => {
  const [, queryObj] = query.queryKey;

  try {
    return await axios.get(
      `/api/v1/teacher/students${queryStringMaker(queryObj)}`,
      {
        headers: {
          Authorization: 'Bearer ' + getAuthToken(),
        },
      },
    );
  } catch (e) {
    return e;
  }
};

export const getStudentbyId = async (
  studentId: string,
): Promise<AxiosResponse> => {
  try {
    return await axios.get(`/api/v1/teacher/students/${studentId}`, {
      headers: {
        Authorization: 'Bearer ' + getAuthToken(),
      },
    });
  } catch (e) {
    return e;
  }
};

export const updateStudent = async (requestObj: any) => {
  try {
    const { data: newData } = await axios.patch(
      `/api/v2/teacher-api/students/${requestObj.studentId}`,
      requestObj,
      {
        headers: {
          Authorization: 'Bearer ' + getAuthToken(),
        },
      },
    );
    return newData;
  } catch (e) {
    return e;
  }
};
