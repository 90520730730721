import { useTranslation } from 'react-i18next';
import {
  Box,
  Grid,
  FormControlLabel,
  Radio,
  Autocomplete,
  TextField,
  Typography,
} from '@mui/material';
import { OmInput } from 'src/shared/components/form-components/om-input';
import { OmDateTimePicker } from 'src/shared/components/form-components/om-date-time-picker';
import { OmDesktopDatePicker } from 'src/shared/components/form-components/om-desktop-date-picker';

export const LessonForm = ({ formik, user, rooms }) => {
  const { t } = useTranslation();

  const isSubject = user?.subjects?.length === 1;

  return (
    <Box display="flex" flexDirection="column">
      <Grid container spacing={2} direction="row">
        <Grid item xs={12} md={4}>
          <Autocomplete
            fullWidth
            disabled={isSubject}
            options={user?.subjects || []}
            onChange={(_e, newValue) => {
              formik.setFieldValue('subject', newValue ? newValue : '');
            }}
            value={formik.values.subject || null}
            getOptionLabel={option => `${option.name} ` || ''}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderInput={params => (
              <TextField
                fullWidth
                name="subject"
                {...params}
                label={t('inputs.labels.subject')}
              />
            )}
          />
          {formik.touched.subject && formik.errors.subject && (
            <Typography
              sx={{
                fontSize: '13px',
                fontWeight: 'bold',
                color: '#FF1943',
              }}
            >
              {formik.errors.subject}
            </Typography>
          )}
        </Grid>
        <Grid item xs={12} md={4}>
          <OmInput
            formik={formik}
            label={t('inputs.labels.topic')}
            name="topic"
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <Autocomplete
            fullWidth
            options={rooms?.data || []}
            onChange={(_e, newValue) => {
              formik.setFieldValue('room', newValue ? newValue : '');
            }}
            value={formik.values.room || null}
            getOptionLabel={option =>
              `${option.name} (${option.branch?.name}) ` || ''
            }
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderInput={params => (
              <TextField
                fullWidth
                name="room"
                {...params}
                label={t('inputs.labels.room')}
              />
            )}
          />
          {formik.touched.room && formik.errors.room && (
            <Typography
              sx={{
                fontSize: '13px',
                fontWeight: 'bold',
                color: '#FF1943',
              }}
            >
              {formik.errors.subject}
            </Typography>
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <OmDesktopDatePicker
            formik={formik}
            label={t('inputs.labels.date')}
            name="date"
            inputFormat="dd/MM/yyyy"
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <Box>
            <b>{t('inputs.labels.isExtra')}</b>
          </Box>
          <FormControlLabel
            control={
              <Radio
                checked={formik.values.isExtra === true}
                onChange={() => formik.setFieldValue('isExtra', true)}
                color="primary"
                name="isExtra"
              />
            }
            label={t('buttons.yes')}
          />
          <FormControlLabel
            control={
              <Radio
                checked={formik.values.isExtra === false}
                onChange={() => formik.setFieldValue('isExtra', false)}
                color="primary"
                name="isExtra"
              />
            }
            label={t('buttons.no')}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
