import TableContextProvider from 'src/shared/provider/table-context-provider';
import GroupDetailsContainer from '../containers/group-details-container';

const GroupDetails = () => {
  return (
    <TableContextProvider>
      <GroupDetailsContainer />
    </TableContextProvider>
  );
};

export default GroupDetails;
