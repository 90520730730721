import { Box, Card, alpha, styled } from '@mui/material';
import LanguageSwitcher from 'src/layouts/AccentHeaderLayout/Header/Buttons/LanguageSwitcher';
import LogoWhite from 'src/shared/components/LogoWhite';

const HeaderWrapper = styled(Card)(
  ({ theme }) => `
    height: ${theme.header.height};
    color: ${theme.header.textColor};
    padding: ${theme.spacing(0, 2)};
    right: 0;
    z-index: 6;
    background-color: ${alpha(theme.colors.primary.main, 0.95)};
    backdrop-filter: blur(3px);
    position: fixed;
    justify-content: space-between;
    width: 100%;
    display: flex;
    align-items: center;
    border-radius: 0;
`,
);

const BoxLogoWrapper = styled(Box)(
  ({ theme }) => `
  margin-right: ${theme.spacing(2)};

  @media (min-width: ${theme.breakpoints.values.lg}px) {
    width: calc(${theme.sidebar.width} - ${theme.spacing(4)});
  }
`,
);

function Header() {
  return (
    <HeaderWrapper>
      <Box display="flex">
        <BoxLogoWrapper>
          <LogoWhite />
        </BoxLogoWrapper>
      </Box>
      <LanguageSwitcher />
    </HeaderWrapper>
  );
}

export default Header;
