import { SyntheticEvent, useContext, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Card, Tab, Tabs } from '@mui/material';
import { styled } from '@mui/system';
import PageWrapper from 'src/shared/components/page-wrapper';
import { useLessonsTable } from 'src/modules/lessons/hooks/use-lessons-table';
import { useGroupLessons } from '../hooks/use-group-lessons';
import GenericTableV2 from 'src/shared/components/generic-table-v2';
import { TableContext } from 'src/shared/contexts/table-context';
import TableContextProvider from 'src/shared/provider/table-context-provider';

const TabsWrapper = styled(Tabs)(
  ({ theme }) => `
    @media (max-width: ${theme.breakpoints.values.md}px) {
      .MuiTabs-scrollableX {
        overflow-x: auto !important;
      }

      .MuiTabs-indicator {
          box-shadow: none;
      }
    }
    `,
);

const GroupDetailsContainer = () => {
  const { t } = useTranslation();

  const { groupId } = useParams();

  const [tab, setTab] = useState('lessons');

  const { data } = useGroupLessons();

  const navigate = useNavigate();

  const [columns, options] = useLessonsTable();
  const { page, limit, setPage, setLimit } = useContext(TableContext);

  const tabs = [
    {
      value: 'lessons',
      label: t('tabs.lessons'),
    },
  ];

  const routes = [
    {
      label: t('pages.home'),
      href: '/dashboard',
    },
    {
      label: t('groups.title'),
      href: '/dashboard/groups',
    },
    {
      label: t('lessons.title'),
      href: '/dashboard/groups/lessons',
    },
  ];
  return (
    <>
      <PageWrapper
        isPaper={false}
        title={t('lessons.title')}
        action={
          <Button
            onClick={() => {
              navigate(`/dashboard/groups/${groupId}/add-lesson`);
            }}
            variant="contained"
          >
            {t('groups.groupDetails.addLesson')}
          </Button>
        }
        routes={routes}
      >
        <Card sx={{ p: 2 }}>
          <TabsWrapper
            onChange={(_event: SyntheticEvent, tabValue: string) => {
              setTab(tabValue);
            }}
            scrollButtons="auto"
            textColor="secondary"
            value={tab}
            variant="scrollable"
          >
            {tabs.map(tab => (
              <Tab
                sx={{ textTransform: 'lowercase' }}
                key={tab.value}
                value={tab.value}
                label={tab.label}
              />
            ))}
          </TabsWrapper>
        </Card>
        {tab === 'lessons' && (
          <Card sx={{ mt: 2 }}>
            <GenericTableV2
              rows={data?.data}
              rowsCount={data?.total}
              columns={columns}
              options={options}
              limit={limit}
              page={page}
              setPage={setPage}
              setLimit={setLimit}
            />
          </Card>
        )}
      </PageWrapper>
    </>
  );
};

export default GroupDetailsContainer;
