import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { useTranslation } from 'react-i18next';

export default function Mark({ marks, setMarks, studentId: student }) {
  const { t } = useTranslation();
  const handleChange = (event: SelectChangeEvent) => {
    const mark = event.target.value;
    const index = marks.findIndex(mark => mark.student === student);
    if (index !== -1) {
      const updatedMarks = [...marks];
      updatedMarks[index] = { student, mark };
      setMarks(updatedMarks);
    } else {
      setMarks(prevMarks => [...prevMarks, { student, mark }]);
    }
  };

  const marksArr = [
    {
      id: null,
      label: t('inputs.labels.mark'),
    },
    {
      id: 2,
      label: 2,
    },
    {
      id: 3,
      label: 3,
    },
    {
      id: 4,
      label: 4,
    },
    {
      id: 5,
      label: 5,
    },
  ];

  return (
    <FormControl sx={{ minWidth: 90 }} size="small">
      <InputLabel id="demo-select-small-label">
        {t('inputs.labels.mark')}
      </InputLabel>
      <Select
        labelId="demo-select-small-label"
        id="demo-select-small"
        value={marks?.find(mark => mark.student === student)?.mark || ''}
        label={t('inputs.labels.mark')}
        onChange={handleChange}
      >
        {marksArr?.map((mark, i) => (
          <MenuItem value={mark.id} key={i}>
            {mark.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
