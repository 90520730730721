import { useQuery } from 'react-query';
import { queryError } from 'src/shared/utils/query-error';
import { useContext } from 'react';
import { TableContext } from 'src/shared/contexts/table-context';
import { getRooms } from '../../groups/services';

export const useRooms = (paginated = true) => {
  const { page, limit } = useContext(TableContext);

  const { data } = useQuery({
    queryKey: ['rooms-get-all', { page, limit, paginated }],
    queryFn: getRooms,
    select: ({ data }: { data: any }) => {
      if (data?.status === 'success') {
        return data;
      }
    },
    onError: queryError,
  });

  return {
    data,
  };
};
