export const addGroupLessonMapper = ({ values }) => {
  return {
    present: values?.present.map(student => student.id),
    absent: values?.absent.map(student => student.id),
    marks: values?.marks,
    topic: values?.topic,
    subject: values?.subject.id,
    room: values?.room?.id,
    isExtra: values?.isExtra,
    date: values?.date,
  };
};

export const feedbackMapper = function (values, lessonId = null) {
  if (!lessonId)
    return {
      student: values?.student?.id,
      title: values?.title,
      content: values?.content,
    };
  return {
    student: values?.student?.id,
    lesson: lessonId,
    title: values?.title,
    content: values?.content,
  };
};
