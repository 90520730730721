import { Card } from '@mui/material';
import { useAbsentTable } from '../hooks/use-absent-table';
import GenericTable from 'src/shared/components/generic-table';

export const AbsentTable = ({ formik }) => {
  const absentData = formik.values.absent;

  const [columns] = useAbsentTable(formik, absentData);

  return (
    <Card>
      <GenericTable data={absentData} columns={columns} />
    </Card>
  );
};
