import {
  Autocomplete,
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useRef } from 'react';
import { useNavigate } from 'react-router';
import { Box } from '@mui/system';
import { OmInput } from 'src/shared/components/form-components/om-input';
import { useLessonFeedbackForm } from '../hooks/use-lesson-feedback-form';

export const AddFeedbackToLesson = function () {
  const { t } = useTranslation();

  const modalRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();

  const {
    formik,
    isSubmitting,
    studentsData,
    isStudentsSubmitting,
    isFeedbackSubmitting,
    studentId,
  } = useLessonFeedbackForm();

  const handleModalClose = () => {
    navigate(-1);
  };

  if (isStudentsSubmitting || isFeedbackSubmitting) {
    return (
      <Dialog
        fullWidth
        open={true}
        ref={modalRef}
        maxWidth="sm"
        onClose={handleModalClose}
      >
        <Grid
          container
          justifyContent={'center'}
          sx={{ padding: '160px 30px' }}
        >
          <CircularProgress />
        </Grid>
      </Dialog>
    );
  }

  return (
    <Dialog
      fullWidth
      open={true}
      ref={modalRef}
      maxWidth="sm"
      onClose={handleModalClose}
    >
      <DialogTitle
        variant="h3"
        sx={{
          p: 3,
          borderBottom: '1px solid #d9dce1',
          marginBottom: '9px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        {t(`inputs.labels.addFeedback`)}
      </DialogTitle>
      <Box sx={{ p: 4 }} component="form" onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <OmInput
              formik={formik}
              label={t('inputs.labels.title')}
              name="title"
            />
          </Grid>

          <Grid item xs={12}>
            <Autocomplete
              fullWidth
              options={studentsData?.usersData || []}
              onChange={(_e, newValue) => {
                formik.setFieldValue('student', newValue ? newValue : '');
              }}
              value={formik.values.student}
              getOptionLabel={(option: any) => {
                return `${option.fullName} (${option.code})`;
              }}
              isOptionEqualToValue={(option: any, value: any) =>
                option?.id === value?.id
              }
              disabled={Boolean(studentId)}
              renderInput={(params: any) => (
                <TextField
                  fullWidth
                  {...params}
                  label={t('inputs.labels.student')}
                />
              )}
            />
            {!!formik.errors.student && formik.touched.student && (
              <Typography
                sx={{
                  fontSize: '13px',
                  fontWeight: 'bold',
                  color: '#FF1943',
                }}
              >
                {formik.errors.student}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12}>
            <OmInput
              multiline={true}
              rows={3}
              formik={formik}
              label={t('inputs.labels.content')}
              name="content"
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} justifyContent="center">
          <Grid
            item
            xs={12}
            md={4}
            sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}
          >
            <Button
              sx={{
                mt: 3,
              }}
              color="primary"
              startIcon={isSubmitting ? <CircularProgress size="1rem" /> : null}
              disabled={isSubmitting}
              type="submit"
              fullWidth
              size="medium"
              variant="contained"
            >
              {t('buttons.confirm')}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Dialog>
  );
};
