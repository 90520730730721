const ruJSON = {
  students: {
    title: 'Ученики',
  },
  homework: {
    title: 'Домашнее задание',
    homeworkForm: 'Форма домашнего задания',
  },
  groups: {
    title: 'Группы',
    groupDetails: {
      title: 'Детали группы',
      addLesson: 'Добавить урок',
    },
  },
  transactions: {
    title: 'Транзакции',
  },
  internalTransactions: {
    title: 'Внутренние транзакции',
  },
  internalTransactionDetails: {
    title: 'Детали внутренней транзакции',
  },
  completion: {
    name: 'Завершение',
  },
  stats: {
    title: 'Статистика',
    internalBalance: 'Внутренний баланс',
  },
  popover: {
    title: 'Профиль',
    signOut: 'Выйти',
    lessonMode: 'Режим урока',
  },
  lessons: {
    title: 'Уроки',
  },
  feedbacks: {
    title: 'Отзывы',
    addFeedback: 'Добавить отзыв',
    feedback: 'Отзыв',
  },
  addLesson: {
    title: 'Добавить урок',
  },
  pages: {
    home: 'Главная',
    studentDetails: 'Детали ученика',
    lessons: 'Уроки',
    addLesson: {
      title: 'Добавить урок',
      lessonDetails: {
        title: 'Детали урока',
        homeworkWillBeAddedLater: 'Домашнее задание будет добавлено позже',
        createHomework: 'Создать домашнее задание',
      },
    },
  },
  modal: {
    pleaseCheckYourFileAgain:
      'Пожалуйста, проверьте свою домашнюю работу снова. Сумма процентов должна быть равна 100.',
    notFoundData: 'Данные не найдены',
    theDataInTheSublistIsLeftBlank: 'Данные в подсписке оставлены пустыми',
    delete: 'Вы уверены, что хотите удалить?',
  },
  message: {
    studentNotFound: 'Ученик не найден',
    homeworkNotFound: 'Домашнее задание не найдено',
    homeworkNotAdded: 'Домашнее задание не добавлено',
    successfulProcess: 'Процесс выполнен успешно',
    successfulImageProcess: 'Изображение успешно обновлено',
    unknownError: 'Неизвестная ошибка',
    dataNotFound: 'Данные не найдены!',
    noHomeworkAddedDoYouWantToAdd:
      'Домашнее задание не добавлено. Хотите добавить?',
    notFound: 'Данные не найдены!',
    invalidCredentials: 'Неверные учетные данные, пожалуйста, войдите снова',
  },
  heading: {
    general: 'Общее',
    management: 'Управление',
    ourTeam: 'Наша команда',
  },
  accounting: {
    title: 'Учет',
    invoiceDetails: {
      title: 'Счет-фактура',
      issuedOn: 'Выставлен',
      issuedBy: 'Выставил',
      teacherBalanceBeforePayment: 'Баланс до оплаты',
      teacherBalanceAfterPayment: 'Баланс после оплаты',
    },
  },
  profile: {
    title: 'Профиль',
    changeCover: 'Изменить обложку',
    tabs: {
      myAccount: 'Мой аккаунт',
      aboutMe: 'Обо мне',
    },
  },
  language: {
    switcher: 'Переключатель языка',
  },
  table: {
    name: 'Имя',
    marks: 'Оценки',
    title: 'Название',
    code: 'Код',
    link: 'Ссылка',
    percentage: 'Процент',
    fullname: 'Полное имя',
    actions: 'Действия',
    status: 'Статус',
    email: 'Электронная почта',
    room: 'Комната',
    students: 'Ученики',
    subject: 'Предмет',
    category: 'Категория',
    amount: 'Сумма',
    date: 'Дата',
    type: 'Тип',
    fatherName: 'Отчество',
    topic: 'Тема',
    student: 'Ученик',
    feedback: 'Отзыв',
    weekdays: 'Смена',
    noDate: 'Дата не выбрана',
  },
  tabs: {
    lessons: 'Уроки',
    students: 'Ученики',
    resources: 'Ресурсы',
    statistics: 'Статистика',
  },
  tooltip: {
    view: 'Просмотр',
    lockedAllHomeworkForStudent:
      'Заблокированы все домашние задания для ученика',
    lockedHomeworkForStudent: 'Заблокировано домашнее задание для ученика',
    unlockHomeworkForStudent: 'Разблокировать домашнее задание для ученика',
    finishEditing: 'Завершить редактирование',
    startEditing: 'Начать редактирование',
    list: 'Список',
    delete: 'Удалить',
    edit: 'Редактировать',
    addStudent: 'Добавить ученика',
    show: 'Нажмите здесь, чтобы показать все отзывы',
  },
  inputs: {
    labels: {
      changePassword: 'Изменить пароль',
      changeEmail: 'Изменить электронную почту',
      addFeedback: 'Добавить отзыв',
      currentPassword: 'Текущий пароль',
      password: 'Пароль',
      marks: 'Оценки',
      mark: 'Оценка',
      passwordConfirm: 'Подтверждение пароля',
      email: 'Электронная почта',
      name: 'Имя',
      content: 'Содержание',
      title: 'Название',
      surname: 'Фамилия',
      subject: 'Предмет',
      gender: 'Пол',
      university: 'Университет',
      interests: 'Интересы',
      dateOfBirth: 'Дата рождения',
      number: 'Номер',
      topic: 'Тема',
      biography: 'Биография',
      searchByCodeAndTopic: 'Поиск по коду и теме',
      code: 'Код',
      search: 'Поиск',
      student: 'Ученик',
      fatherName: 'Отчество',
      fullName: 'Полное имя',
      query: 'Запрос',
      note: 'Заметка',
      tags: 'Теги',
      description: 'Описание',
      rowsPerPage: 'Строк на странице',
      group: 'Группа',
      teacher: 'Педагог',
      extra: 'Дa',
      isExtra: 'Дополнительный урок',
      notExtra: 'Нет',
      room: 'Комната',
      date: 'Дата',
      weekdays: 'Смена',
    },
    placeholders: {},
  },
  buttons: {
    confirm: 'Подтвердить',
    importExcelFile: 'Импортировать файл Excel',
    add: 'Добавить',
    edit: 'Редактировать',
    seeMore: 'Посмотреть больше',
    close: 'Закрыть',
    addHomework: 'Добавить домашнее задание',
    confirmWithoutAdd: 'Подтвердить без добавления',
    addFeedback: 'Добавить отзыв',
    cancel: 'Отмена',
    update: 'Обновить',
    yes: 'Да',
    no: 'Нет',
  },
  actionButtons: {
    delete: 'Удалить',
  },
  comments: {
    name: 'Комментарии',
  },
  yup: {
    currentPassword: 'Текущий пароль обязателен',
    password: {
      required: 'Пароль обязателен',
      validation: 'Пароль должен содержать минимум 3 символа.',
    },
    passwordConfirm: {
      required: 'Подтверждение пароля обязательно.',
      validation: 'Не совпадает с повторным вводом пароля',
    },
    email: {
      required: 'Электронная почта обязательна',
      validation: 'Электронная почта введена неверно',
    },
    inputs: {
      duration: {
        required: 'Длительность обязательна',
        max: 'Максимум 200 мин.',
      },
      percentage: {
        required: 'Процент обязателен',
        total: 'Сумма должна быть 100%',
      },
      name: 'Имя обязательно',
      surname: 'Фамилия обязательна',
      gender: 'Пол обязателен',
      university: 'Университет обязателен',
      topic: 'Тема обязательна',
      room: 'Комната обязательна',
      lessonStarting: 'Начало урока обязательно',
      lessonEnding: 'Окончание урока обязательно',
      title: 'Название обязательно',
      link: 'Ссылка обязательна',
      homework: 'Домашнее задание обязательно',
      biography: 'Биография обязательна',
      number: 'Номер обязателен',
      student: 'Ученик обязателен',
    },
  },
  subject: 'Предмет',
  room: 'Комната',
  lessonStarting: 'Начало урока',
  lessonEnding: 'Окончание урока',
  duration: 'Длительность',
  description: 'Описание',
  title: 'Название',
  percentage: 'Процент',
  link: 'Ссылка',
  present: 'Присутствует',
  absent: 'Отсутствует',
  topic: 'Тема',
  login: {
    signIn: 'Войти',
    info: 'Заполните поля ниже, чтобы войти в свою учетную запись.',
    lostPassword: 'Забыли пароль ?',
    signUp: 'Зарегистрироваться здесь',
  },
  recoverPassword: {
    title: 'Восстановить пароль',
    info: 'Введите электронную почту, использованную при регистрации, чтобы сбросить пароль',
    sendPassword: 'Отправить мне новый пароль',
    signInAgain: 'Хотите попробовать войти снова?',
    clickHere: 'Нажмите здесь',
  },
  typography: {
    present: 'Присутствует',
    absent: 'Отсутствует',
    currentMaintenance:
      'Сайт в настоящее время находится на техническом обслуживании',
    appologizeMaintenance: 'Приносим извинения за любые вызванные неудобства',
    notFeedback: 'Обратная связь не найдена!',
  },
  languageSwitcher: 'Язык',
  amount: 'Сумма',
  selectInput: {
    female: 'Женский',
    male: 'Мужской',
    unknown: 'Неизвестно',
  },
};

export default ruJSON;
