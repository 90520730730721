import { DesktopDatePicker } from '@mui/lab';
import { TextField } from '@mui/material';

export function OmDesktopDatePicker({ formik, label, name, inputFormat }) {
  const today = new Date();
  return (
    <DesktopDatePicker
      value={formik.values[name]}
      onChange={newDate => {
        formik.setFieldValue(name, newDate);
      }}
      maxDate={today}
      label={label}
      inputFormat={inputFormat}
      renderInput={params => (
        <TextField {...params} margin="none" variant="outlined" fullWidth />
      )}
    />
  );
}
